import { FC, PropsWithChildren, useMemo } from "react";
import { Page as CorePage, PageProps as CorePageProps } from "am-tax-fe-core";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuDivider, MenuItem, MenuItemProps } from "@chakra-ui/react";
import { IconSettings2 } from "@tabler/icons-react";
import { useQueryMyInformation } from "../api/depr/me";

export type PageProps = PropsWithChildren<Omit<CorePageProps, "location" | "menuItems">>;

export const Page: FC<PageProps> = ({ children, ...pageProps }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const currentUserQuery = useQueryMyInformation(),
        hasAdmin = !!currentUserQuery.data?.permissions.find(p => p === "HasSystemAdmin");

    const menuItems = useMemo(() => {

        if(!hasAdmin){
            return [];
        }

        const extraMenuItems: MenuItemProps[] = [
                {
                    icon: <IconSettings2 />,
                    children: 'Admin',
                    onClick: () => {
                        navigate("/admin")
                    }
                }
            ];
        return [...extraMenuItems.map((p,ix) => <MenuItem key={`extra-menu-${ix}`} {...p} />), <MenuDivider key="extra-menu-div-0" />];
    },[hasAdmin])

    return (
        <CorePage location={location} {...pageProps} menuItems={menuItems}>
            {children}
        </CorePage>
    );
};