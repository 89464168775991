import { FunctionComponent, useRef, useState } from "react";
import { Box, Card, Divider, Flex, Heading, HStack, Link, Stack, Text, useToast } from "@chakra-ui/react";
import { Page } from "../Page";
import { FileUploader, FileContainer } from "am-tax-fe-core";
import { FileHistory } from "./FileHistory";
import { IconFileDownload } from "@tabler/icons-react";
import { BatchUploadedStats } from "am-tax-fe-core/dist/components/FileUploader/FileUploader";
import { PieChart } from "../../components/Charts/PieChart";
import { BarChart } from "../../components/Charts/BarChart";
import { useQueryClient } from "@tanstack/react-query";
import { useMutationProcessSessionCreate } from "../../api";
import { queries } from "../../api/depr/queries";

export type dummyDataProps = {
    color: string;
    id: string;
    label: string;
    value: number;
};

export const TransactionsPage: FunctionComponent = () => {
    const toast = useToast();
    const [currentProcessSessionId, setCurrentProcessSessionId] = useState<string | undefined>(undefined);
    const [fileQueue, setFileQueue] = useState<FileContainer[]>([]);
    const fileQueueRef = useRef<FileContainer[]>(fileQueue);
    const queryClient = useQueryClient();
    const uploadProcessSessionFiles = useMutationProcessSessionCreate();

    const onFileDrop = (fileContainers: FileContainer<unknown>[], longestUniquePaths: string[]) => {
        return new Promise<FileContainer<unknown>[]>(resolve => {
            console.log(`onDrop(fileContainers)`, fileContainers);
            console.log(longestUniquePaths);
            setTimeout(() => {
                resolve(fileContainers);
            }, 1000);
        });
    };
    const onFileAdded = async (fileContainer: FileContainer) => {
        const processSession = await uploadProcessSessionFiles.mutateAsync({
            files: fileContainer,
        });
        setCurrentProcessSessionId(processSession.id);
        toast({
            title: "File Uploaded",
            description: "File uploaded successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    const onFileRemoved = async (fileContainer: FileContainer) => {
        console.log(`onFileCancelled(fileContainer)`, fileContainer);

        const updatedQueue = [];
        const queue = [...fileQueueRef.current];
        for (const file of queue) {
            if (file.index !== fileContainer.index) {
                updatedQueue.push(file);
            }
        }
        setFileQueue(updatedQueue);
        fileQueueRef.current = updatedQueue;
    };

    const onFileUploaded = async (fileContainer: FileContainer, uploadsRemaining: number) => {
        console.log(`onFileUploaded(fileContainer,uploadsRemaining)`, fileContainer, uploadsRemaining);
        setFileQueue([]);
        console.log("file uploaded");
        toast({
            title: "Saved",
            description: "Saved Request and Uploaded Files.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    const onBatchUploaded = async (stats: BatchUploadedStats) => {
        await queryClient.invalidateQueries(queries.processSession.stats(stats));
        console.log(`onBatchUploaded(stats)`, stats);
        const empty: FileContainer[] = [];
        setFileQueue(empty);
        fileQueueRef.current = empty;
    };
    const onUploadError = (fileContainer: FileContainer<unknown>, e: unknown) => {
        console.log(`onUploadError(fileContainer, e)`, fileContainer, e);
    };

    return (
        <Page title="Transactions" p={".5rem"}>
            <Stack padding={5} direction={"column"}>
                <Box sx={{ height: "100%", width: "auto" }}>
                    <Heading as={"h2"} size={"lg"}>
                        <Flex justifyContent="space-between">
                            <Text as="b">Import</Text>
                            <Box>
                                <Link href={"#"}>
                                    <HStack>
                                        {" "}
                                        <IconFileDownload />
                                        <Text variant={"label"}>Download Template</Text>
                                    </HStack>
                                </Link>
                            </Box>
                        </Flex>
                    </Heading>

                    <Divider mb={5} />
                    <Card variant={"withShadow"} padding={"3rem"} width={"100%"}>
                        <FileUploader
                            flattenFolders={true}
                            onDrop={onFileDrop}
                            onFileAdded={onFileAdded}
                            onFileCancelled={onFileRemoved}
                            onFileUploaded={onFileUploaded}
                            onBatchUploaded={onBatchUploaded}
                            onUploadError={onUploadError}
                        />
                    </Card>
                </Box>
                <Box sx={{ height: "100%" }}>
                    <Heading as={"h2"} size={"lg"}>
                        <b>Statistics</b>
                    </Heading>
                    <Divider mb={5} />
                    <HStack spacing={10} align={"center"}>
                        <Card variant={"withShadow"} padding={"2rem"} width={"500px"} height={"500px"}>
                            <BarChart></BarChart>
                        </Card>
                        <Card variant={"withShadow"} padding={"2rem"} width={"500px"} height={"500px"}>
                            <PieChart></PieChart>
                        </Card>
                    </HStack>
                </Box>
                <Box sx={{ height: "100%" }}>
                    <Heading as={"h2"} size={"lg"}>
                        <b>Process Session History</b>
                    </Heading>
                    <Divider mb={5} />
                    <Card variant={"withShadow"} padding={"1rem"}>
                        <FileHistory processSessionId={currentProcessSessionId}></FileHistory>
                    </Card>
                </Box>
            </Stack>
        </Page>
    );
};
