import { FunctionComponent } from "react";
import {
    Box,
    Divider,
    DrawerBody,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    IconButton,
    Input,
    Select,
    Spacer,
    StackDivider,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { useConfirm } from "am-tax-fe-core";
import { AdminUserRoleEditorForm } from "./AdminUserRoleEditorForm";
import { AdminUserFlagsEditorForm } from "./AdminUserFlagsEditorForm";
import { IconMoodPuzzled, IconTrash } from "@tabler/icons-react";
import { ProjectsByUserIdQueryResponse, useQueryProjectsForUser, UsersQueryResponse } from "../../../../api/depr/adminUsers";
import { DeleteProjectUserArgs, useMutationDeleteProjectUser } from "../../../../api/depr/adminProjects";
import { UpdateProjectUserAccessRoleArgs, useMutationUpdateProjectUserAccessRole } from "../../../../api/depr/adminProjectUsers";

interface AdminUserDrawerBodyProps {
    user: UsersQueryResponse;
}

export const AdminUserDrawerBody: FunctionComponent<AdminUserDrawerBodyProps> = props => {
    const { user } = props;

    const tabs = [
        {
            title: "Details",
            content: <UserDetailsTabPanel key={0} user={user} />,
        },
        {
            title: "Security",
            content: <UserSecurityTabPanel key={1} user={user} />,
        },
        {
            title: "Project",
            content: <UserProjectsTabPanel key={2} user={user} />,
        },
    ];

    return (
        <DrawerBody>
            <Tabs>
                <TabList>
                    {tabs.map((tab, ix) => (
                        <Tab key={ix}>{tab.title}</Tab>
                    ))}
                </TabList>
                <TabPanels>{tabs.map((tab, ix) => tab.content)}</TabPanels>
            </Tabs>
        </DrawerBody>
    );
};

const UserDetailsTabPanel: FunctionComponent<{ user: UsersQueryResponse }> = ({ user }) => {
    const { firstName, lastName, email } = user;
    return (
        <TabPanel>
            <TabPanelHeading title="User details" />
            <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input placeholder="First Name" value={firstName} readOnly />
            </FormControl>
            <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input placeholder="Last Name" value={lastName} readOnly />
            </FormControl>
            <FormControl>
                <FormLabel>Email Address</FormLabel>
                <Input placeholder="User's email address" value={email} readOnly />
            </FormControl>
        </TabPanel>
    );
};

const UserSecurityTabPanel: FunctionComponent<{ user: UsersQueryResponse }> = ({ user }) => {
    return (
        <TabPanel>
            <TabPanelHeading title="Security" />
            {user && <AdminUserRoleEditorForm user={user} />}
            {user && <AdminUserFlagsEditorForm user={user} />}
        </TabPanel>
    );
};

const UserProjectsTabPanel: FunctionComponent<{ user: UsersQueryResponse }> = ({ user }) => {
    const projectAssignmentsToUserQuery = useQueryProjectsForUser(user.id);
    const projectAssignmentsData = projectAssignmentsToUserQuery?.data ?? [];

    return (
        <TabPanel>
            <TabPanelHeading title="Project assignments" />
            <VStack divider={<StackDivider />}>
                {projectAssignmentsData && projectAssignmentsData.length > 0 ? (
                    projectAssignmentsData.map(projectAssignment => (
                        <ProjectAssignmentControl key={projectAssignment?.projectId} user={user} assignment={projectAssignment} />
                    ))
                ) : (
                    <NoProjectAssignments />
                )}
            </VStack>
        </TabPanel>
    );
};

const TabPanelHeading = (props: { title: string }) => (
    <Heading fontWeight={"700"} fontSize={"1.2rem"} size={"md"}>
        {props.title}
    </Heading>
);

const NoProjectAssignments = () => (
    <VStack mt={5}>
        <Divider />
        <Icon as={IconMoodPuzzled} boxSize={12} />
        <Text>No project assignments found</Text>
        <Divider />
    </VStack>
);

const ProjectAssignmentControl = (props: { user: UsersQueryResponse; assignment: ProjectsByUserIdQueryResponse }) => {
    const { user, assignment } = props;

    const { confirm, ConfirmDialog } = useConfirm({
        title: "Remove Project Assignment?",
        prompt: "Are you sure you want to remove this project assignment?",
    });

    const deleteProjectUser = useMutationDeleteProjectUser();
    const updateProjectUserAccessRole = useMutationUpdateProjectUserAccessRole();
    const toast = useToast();

    const updateAccessRole = (data: any) => {
        const args: UpdateProjectUserAccessRoleArgs = {
            ...data,
        };
        updateProjectUserAccessRole.mutateAsync(args, {
            onSuccess: () => {
                toast({
                    title: "User Access Role Updated",
                    status: "success",
                    description: "User access role has been successfully updated",
                    duration: 3000,
                });
            },
        });
    };

    const deleteUserFromProject = (data: DeleteProjectUserArgs) => {
        deleteProjectUser.mutateAsync(data);
    };

    return (
        <Flex width="100%" alignItems={"center"} gap={3}>
            <Box>{assignment.projectName}</Box>
            <Spacer />
            <Box>
                <Select
                    disabled={false}
                    value={assignment.projectRole}
                    onChange={e =>
                        updateAccessRole({
                            userId: user.id ?? "",
                            projectId: assignment.projectId ?? "",
                            role: e.target.value,
                        })
                    }
                >
                    <option value="Administrator">Administrator</option>
                    <option value="Contributor">Contributor</option>
                    <option value="Reader">Reader</option>
                </Select>
            </Box>
            <Box>
                <IconButton
                    aria-label={"Delete item"}
                    icon={<Icon as={IconTrash} />}
                    onClick={async event => {
                        const result = await confirm();
                        if (result) {
                            const userId = user.id;
                            const projectId = assignment.projectId;
                            deleteUserFromProject({
                                userId: userId ?? "",
                                projectId: projectId ?? "",
                            });
                        }
                    }}
                />
            </Box>
            <ConfirmDialog />
        </Flex>
    );
};
