import { FC, useMemo } from "react";
import { ApplicationLayoutBoxShadow, LeftNav as LeftNavCore, NavGroup, NavItemProps, Shimmer } from "am-tax-fe-core";
import {
    IconAsset,
    IconDashboard,
    IconGitCherryPick,
    IconHomeSearch,
    IconListSearch,
    IconSettings,
    IconUpload
} from "@tabler/icons-react";
import { useMatch } from "react-router-dom";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { useQueryMyProjects } from "../api/depr/me";

export const LeftNav: FC = () => {

    const matchesProject = useMatch("/project/:tenantRefId/*"),
        tenantRefId = matchesProject?.params?.tenantRefId;

    const matchesProjectTransactions = !!useMatch("/project/:tenantRefId/transactions/*");
    const matchesProjectProcessSession = useMatch("/project/:tenantRefId/transactions/import/:processSessionId/*"),
        processSessionId = matchesProjectProcessSession?.params.processSessionId;

    const currentUserProjectsQuery = useQueryMyProjects(),
        currentUserProjects = currentUserProjectsQuery.data ?? [],
        currentProject = tenantRefId ? currentUserProjects.find(p => p.refId === tenantRefId) : undefined;


    const items = useMemo(() => {
        const items: Array<NavGroup | NavItemProps> = [
            {
                to: "/projects",
                leftIcon: <IconHomeSearch size="1rem" />,
                text: "My projects"
            }
        ];

        if (tenantRefId) {
            const group: NavGroup = {
                isGroup: true,
                groupHeading: (
                    <Box px={"1rem"} py={3} m={0} bg={"white"} boxShadow={ApplicationLayoutBoxShadow}>
                        {currentProject ? (
                            <Flex flexDirection={"column"} alignItems={"center"} gap={"7px"}>
                                <Heading variant={"info"} size={"sm"} maxWidth={"100%"} textAlign={"center"}>
                                    {currentProject.name}
                                </Heading>
                            </Flex>
                        ) : (
                            <Shimmer />
                        )}
                    </Box>
                ),
                items: [
                    {
                        to: `/project/${tenantRefId}/dashboard`,
                        leftIcon: <IconDashboard size="1rem" />,
                        text: "Dashboard"
                    },
                    {
                        to: `/project/${tenantRefId}/assets`,
                        leftIcon: <IconAsset size="1rem" />,
                        text: "Assets"
                    },
                    {
                        to: `/project/${tenantRefId}/transactions`,
                        leftIcon: <IconGitCherryPick size="1rem" />,
                        text: "Transactions"
                    }
                ]
            };

            if (matchesProjectTransactions) {
                group.items.push({
                    isSubgroup: true,
                    items: [
                        {
                            to: `/project/${tenantRefId}/transactions/search`,
                            leftIcon: <IconListSearch size="1rem" />,
                            text: "Transaction Search"
                        },
                        {
                            to: `/project/${tenantRefId}/transactions/import`,
                            leftIcon: <IconUpload size="1rem" />,
                            text: "Import"
                        },
                        ...processSessionId ? [
                            {
                                to: `/project/${tenantRefId}}/transactions/import/${processSessionId}`,
                                leftIcon: <IconUpload size="1rem" />,
                                text: "PS_RENAME_ME"
                            }
                        ] : []
                    ]
                });
            }

            group.items.push({
                to: `/project/${tenantRefId}/settings`,
                leftIcon: <IconSettings size="1rem" />,
                text: "Settings"
            })

            items.push(group);
        }

        return items;
    }, [tenantRefId, currentProject, matchesProjectTransactions, processSessionId]);

    return <LeftNavCore logo={{ type: "us" }} appName={import.meta.env.VITE_APP_NAME} items={items} />;
};