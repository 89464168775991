import { fetcher } from "./configure";
import { components, paths } from "../generated/generated-api";
import { errorMessage, execute } from "am-tax-fe-core";
import { useQuery } from "@tanstack/react-query";
import { queries } from "./queries";


export function useQueryUserActivity(filters: GetUserActivityArgs){
    return useQuery({
        ...queries.adminUserActivity.list(filters),
        ...errorMessage("Unable to get user activity")
    })
}
export const getUserActivity = async (args: GetUserActivityArgs) : Promise<GetUserActivityResponse[]> => execute(_getUserActivity(args!)); // TODO Ask John why I need a '~!' here
const _getUserActivityPath = "/api/admin/UserActivity";
export type GetUserActivityResponse = components["schemas"]["UserActivityResponse"];
export type GetUserActivityArgs = paths[typeof _getUserActivityPath]["get"]["parameters"]["query"];
const _getUserActivity = fetcher.path(_getUserActivityPath).method("get").create();

export function useQueryUserActivityAreas() {
    return useQuery({
        ...queries.adminUserActivity.areas,
        ...errorMessage("Unable to retrieve activity areas")
    })
}
export const getUserActivityAreas = async (): Promise<string[]> => execute(_getUserActivityAreas({}));
const _getUserActivityAreasPath = "/api/admin/UserActivity/Areas";
const _getUserActivityAreas = fetcher.path(_getUserActivityAreasPath).method("get").create();

export function useQueryUserActivityUsers() {
    return useQuery({
        ...queries.adminUserActivity.users,
        ...errorMessage("Unable to retrieve activity users")
    })
}
export const getUserActivityUsers = async (): Promise<UserActivityUsersResponse[]> => execute(_getUserActivityUsers({}));
const _getUserActivityUsersPath = "/api/admin/UserActivity/Users";
export type UserActivityUsersResponse = components["schemas"]["KeyValuePairOfStringAndString"];
const _getUserActivityUsers = fetcher.path(_getUserActivityUsersPath).method("get").create();

export function useQueryUserActivityProjects() {
    return useQuery({
        ...queries.adminUserActivity.projects,
        ...errorMessage("Unable to retrieve activity projects")
    })
}
export const getUserActivityProjects = async (): Promise<UserActivityProjectsResponse[]> => execute(_getUserActivityProjects({}));
const _getUserActivityProjectsPath = "/api/admin/UserActivity/Projects";
export type UserActivityProjectsResponse = components["schemas"]["KeyValuePairOfStringAndString"];
const _getUserActivityProjects = fetcher.path(_getUserActivityProjectsPath).method("get").create();

export function useQueryUserActivityActions() {
    return useQuery({
        ...queries.adminUserActivity.actions,
        ...errorMessage("Unable to retrieve activity actions")
    })
}
export const getUserActivityActions = async (): Promise<string[]> => execute(_getUserActivityActions({}));
const _getUserActivityActionsPath = "/api/admin/UserActivity/Actions";
const _getUserActivityActions = fetcher.path(_getUserActivityActionsPath).method("get").create();