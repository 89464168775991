import { FunctionComponent, useMemo, useState } from "react";

import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Divider,
    Flex,
    Heading,
    HStack,
    Icon,
    IconButton,
    Image,
    Spacer,
    Stack,
    StackDivider,
    Text,
    VStack
} from "@chakra-ui/react";
import { Page } from "../Page";
import { Filter, Shimmer } from "am-tax-fe-core";
import { useNavigate } from "react-router";
import { FallbackImage } from "./FallbackImage";
import { IconStar, IconStarFilled } from "@tabler/icons-react";
import { useMutationAddProjectFavorite, useMutationRemoveProjectFavorite, useQueryMyProjects } from "../../api/depr/me";

export const MyProjectsPage: FunctionComponent = () => {
    const myProjectsQuery = useQueryMyProjects();
    const addProjectFavorite = useMutationAddProjectFavorite(),
        removeProjectFavorite = useMutationRemoveProjectFavorite();

    const isProjectFavoriteSaving = addProjectFavorite.isPending || removeProjectFavorite.isPending;

    const navigate = useNavigate();

    const projects = myProjectsQuery.data ?? [];
    const [filter, setFilter] = useState("");
    const filteredProjects = useMemo(() => {
        const projects = myProjectsQuery.data || [];
        return filter.length ? projects.filter(project => project.name.toLowerCase().includes(filter.toLowerCase())) : projects;
    }, [myProjectsQuery.data, filter]);

    const favorites = projects.filter(p => p.isFavorite),
        hasFavorites = favorites.length > 0;

    function updateFilter(value: string) {
        setFilter(value);
    }

    return (
        <Page title="My Projects">
            {hasFavorites && (
                <Card overflow={"hidden"} bgGradient={`linear(to-br, blue.700, blue.500)`}>
                    <Box textAlign="left" color={"whiteAlpha.800"} padding={"1rem"}>
                        Favorites
                    </Box>
                    <HStack
                        spacing="1px"
                        alignItems="stretch"
                        overflowY="auto"
                        divider={
                            <Box border={0}>
                                <Divider borderColor="blue.300" />
                            </Box>
                        }
                        padding={"1rem"}
                    >
                        {favorites.map(project => {
                            return (
                                <Card
                                    key={project.id}
                                    backgroundColor={"whiteAlpha.800"}
                                    _hover={{ bg: "white" }}
                                    cursor={"pointer"}
                                    onClick={() => {
                                        navigate(`/project/${project.refId}`);
                                    }}
                                >
                                    {project.brandLogo ? (
                                        <CardHeader>
                                            <Stack align="center">
                                                <Image src={project.brandLogo} boxSize={"100px"} />
                                                <Heading color={project.brandColor}>{project.name}</Heading>
                                            </Stack>
                                        </CardHeader>
                                    ) : (
                                        <CardHeader>
                                            <Heading color={project.brandColor}>{project.name}</Heading>
                                        </CardHeader>
                                    )}

                                    <CardBody>
                                        <Stack divider={<StackDivider />} spacing="4">
                                            <Box>
                                                <Text pt="2" fontSize="xs">
                                                    {project.description}
                                                </Text>
                                            </Box>
                                        </Stack>
                                    </CardBody>
                                </Card>
                            );
                        })}
                    </HStack>
                </Card>
            )}

            <Card overflow={"hidden"} minHeight={"50%"} bgGradient={`linear(to-br, blue.700, blue.500)`}>
                <VStack padding="2rem" spacing="1rem" alignItems="stretch">
                    <Box>
                        <Filter placeholder="Filter projects" onChange={updateFilter} count={{ total: projects.length, shown: filteredProjects.length }} />
                    </Box>

                    {!myProjectsQuery.isLoading && myProjectsQuery.data?.length === 0 && (
                        <Box textAlign="center" color={"whiteAlpha.800"}>
                            No Projects Found
                        </Box>
                    )}

                    <VStack
                        spacing="1px"
                        alignItems="stretch"
                        overflowY="auto"
                        divider={
                            <Box border={0}>
                                <Divider borderColor="blue.300" />
                            </Box>
                        }
                    >
                        {myProjectsQuery.isLoading
                            ? Array(7)
                                  .fill(null)
                                  .map((val, i) => <Shimmer key={i} height="40px" mode={"dark"} marginRight="2rem" />)
                            : null}
                        {filteredProjects?.map(project => (
                            <Card
                                key={project.id}
                                direction="row"
                                backgroundColor={"transparent"}
                                _hover={{ bg: "whiteAlpha.400" }}
                                borderColor={"transparent"}
                                cursor={"pointer"}
                                onClick={() => {
                                    navigate(`/project/${project.refId}`);
                                }}
                            >
                                <Image boxSize={"50px"} src={project.brandLogo} fallbackSrc={FallbackImage} borderRadius={"4px"} />
                                <HStack marginLeft="1rem" w={"full"}>
                                    <Flex justifyItems={"right"} w={"full"}>
                                        <Box color={"whiteAlpha.900"}>{project.name}</Box>
                                        <Spacer />
                                        <IconButton
                                            colorScheme="transparent"
                                            icon={project.isFavorite ? <Icon as={IconStarFilled} color={"yellow"} /> : <Icon as={IconStar}></Icon>}
                                            aria-label={"favorite project"}
                                            isDisabled={isProjectFavoriteSaving}
                                            onClick={async event => {
                                                event.stopPropagation(); //Required to prevent redirection to client project page.
                                                if(!project.isFavorite) {
                                                    await addProjectFavorite.mutateAsync(project.id)
                                                } else {
                                                    await removeProjectFavorite.mutateAsync(project.id);
                                                }
                                            }}
                                        />
                                    </Flex>
                                </HStack>
                            </Card>
                        ))}
                    </VStack>
                </VStack>
            </Card>
        </Page>
    );
};