import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
    getProjectById,
    GetProjectDetailsResponse,
    getProjects,
    getProjectUsers,
    ProjectUsersResponse
} from "./adminProjects";

export const adminProjectQueries = createQueryKeys("adminProjects", {
    all: {
        queryKey: null,
        queryFn: () => getProjects()
    },
    byId: (projectId: string | undefined) => ({
        queryKey: [projectId],
        queryFn: () => (projectId ? getProjectById({ projectId }) : Promise.resolve({} as GetProjectDetailsResponse)),
        contextQueries: {
            users: {
                queryKey: null,
                queryFn: () => (projectId ? getProjectUsers({ projectId }) : Promise.resolve([] as ProjectUsersResponse[]))
            }
        }
    })
});