import { fetcher } from "./configure";
import { components, paths } from "../generated/generated-api";
import { errorMessage, execute, mutationErrorHandler, useToast } from "am-tax-fe-core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queries } from "./queries";

export function useQueryMyInformation(){
    return useQuery({
        ...queries.me.info,
        ...errorMessage("Could not get your details")
    });
}
export const getMyInformation = async (): Promise<CurrentUserQueryResponse> => execute(_getMyInformation({}));
const getMyInformationPath = "/api/Me";
export type CurrentUserQueryResponse = components["schemas"]["CurrentUserQueryResponse"];
const _getMyInformation = fetcher.path(getMyInformationPath).method("get").create();

export function useQueryMyProjects() {
    return useQuery({
        ...queries.me.projects,
        ...errorMessage("Could not get your projects")
    });
}
export const getMyProjects = async (): Promise<CurrentUserProjectQueryResponse[]> => execute(_getMyProjects({}));
const getMyProjectsPath = "/api/Me/projects";
export type CurrentUserProjectQueryResponse = components["schemas"]["CurrentUserProjectQueryResponse"];
const _getMyProjects = fetcher.path(getMyProjectsPath).method("get").create();

export function useMutationAddProjectFavorite(){
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (projectId: string) => setProjectFavorite({projectId, Value: true}),
        onSuccess: async (data, projectId) => {
            queryClient.setQueryData<CurrentUserProjectQueryResponse[]>(queries.me.projects.queryKey, oldData =>
                oldData
                    ? oldData.map(project =>
                        project.id === projectId
                            ? {
                                ...project,
                                isFavorite: data,
                            }
                            : project,
                    )
                    : oldData,
            );
        },
        ...mutationErrorHandler(toast, "Unable to update favorites."),
    });
}
export function useMutationRemoveProjectFavorite(){
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (projectId: string) => setProjectFavorite({projectId, Value: false}),
        onSuccess: async (data, projectId) => {
            queryClient.setQueryData<CurrentUserProjectQueryResponse[]>(queries.me.projects.queryKey, oldData =>
                oldData
                    ? oldData.map(project =>
                        project.id === projectId
                            ? {
                                ...project,
                                isFavorite: data,
                            }
                            : project,
                    )
                    : oldData,
            );
        },
        ...mutationErrorHandler(toast, "Unable to update favorites."),
    });
}
export const setProjectFavorite = async (args: SetProjectFavoriteArgs): Promise<SetProjectFavoriteResponse> => execute(_setProjectFavorite(args));
const setProjectFavoritePath = "/api/Me/projects/{projectId}/IsFavorite";
export type SetProjectFavoriteResponse = boolean;
export type SetProjectFavoriteArgs = paths[typeof setProjectFavoritePath]["put"]["parameters"]["path"] &
    paths[typeof setProjectFavoritePath]["put"]["parameters"]["query"];
const _setProjectFavorite = fetcher.path(setProjectFavoritePath).method("put").create();