import { FC, useMemo } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { UnauthorizedPage } from "./UnauthorizedPage";
import { NotFoundPage } from "./NotFoundPage";
import { DashboardPage } from "./DashboardPage";
import { AssetsPage } from "./AssetsPage";
import { TransactionsPage } from "./TransactionPage";
import { SettingsPage } from "./SettingsPage";
import { AdministrationPage } from "./AdministrationPage";
import { ProcessesPage } from "./ProcessesPage";
import { MyProjectsPage } from "./MyProjectsPage";
import { AdminActivityStreamPage, AdminDashboardPage, AdminProjectsPage, AdminUsagePage, AdminUsersPage } from "./Administration";
import { AdminSignalRContextProvider } from "../components/SignalRContext";
import { ProjectSignalRContextProvider } from "../components/SignalRContext/ProjectSignalRContextProvider";
import ApplicationLayout from "./ApplicationLayout";
import { registerGlobal403Handler } from "am-tax-fe-core";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<ApplicationLayout />}>
            <Route path="/" element={<Navigate to="projects" replace />} />
            <Route path="projects" element={<MyProjectsPage />} />
            <Route path="project/:tenantRefId" element={<ProjectSignalRContextProvider />}>
                <Route index element={<Navigate to="dashboard" replace />} />
                <Route path="dashboard" element={<DashboardPage />} />
                <Route path="assets" element={<AssetsPage />} />
                <Route path="transactions" element={<TransactionsPage />} />
                <Route path="transactions/process-session" element={<ProcessesPage />} />
                <Route path="settings" element={<SettingsPage />} />
                <Route path="administration" element={<AdministrationPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Route>
            <Route path="admin" element={<AdminSignalRContextProvider />}>
                <Route index element={<Navigate to="dashboard" replace />} />
                <Route path="dashboard" element={<AdminDashboardPage />} />
                <Route path="activity-stream" element={<AdminActivityStreamPage />} />
                <Route path="projects" element={<AdminProjectsPage />} />
                <Route path="usage" element={<AdminUsagePage />} />
                <Route path="users" element={<AdminUsersPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Route>
            <Route path="Unauthorized" element={<UnauthorizedPage />} />
            <Route path="404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Route>,
    ),
);

export const PageRoutes: FC = () => {
    useMemo(() => {
        registerGlobal403Handler(() => {
            router.navigate("/Unauthorized");
        });
    }, []);

    return <RouterProvider router={router} />;
};
