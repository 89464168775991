import { FunctionComponent } from "react";
import { Page } from "../../Page";
import { Box, ScaleFade, SimpleGrid, Spacer, Text } from "@chakra-ui/react";

export const AdminDashboardPage: FunctionComponent = () => {
    return (
        <>
            <Page title={"Administration"}>
                <Text>Aye, matey. Ye've found the page of administration.</Text>
                <SimpleGrid columns={2} spacing={8} py={20}>
                    <Box height="80px">
                        <ScaleFade initialScale={0.7} in style={{transitionDuration: '500ms'}}>
                            <Text fontSize="4xl" color="brandPrimary.darkBlue">Graphs!</Text>
                        </ScaleFade>
                    </Box>
                    <Box height="80px">
                        <Spacer />
                    </Box>
                    <Box height="80px">
                        <Spacer />
                    </Box>
                    <Box height="80px">
                        <ScaleFade initialScale={0.4} in style={{transitionDuration: '800ms', transitionDelay: '100ms'}}>
                            <Text fontSize="6xl" color="brandPrimary.brightBlue">Charts!</Text>
                        </ScaleFade>
                    </Box>
                    <Box height="80px">
                        <ScaleFade initialScale={0.1} in style={{transitionDuration: '2000ms'}}>
                            <Text fontSize="2xl" color="brandPrimary.lightBlue">Information that will change your life!</Text>
                        </ScaleFade>
                    </Box>
                    <Box height="80px">
                        <Spacer />
                    </Box>
                </SimpleGrid>
            </Page>
        </>
    );
};