import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getAllUsers, getProjectsByUserId, getUserById, GetUserByIdArgs, getUserPresences } from "./adminUsers";

export const adminUsersQueries = createQueryKeys('adminUsers',{
    all: {
        queryKey: null,
        queryFn: () => getAllUsers()
    },
    byId: (args: GetUserByIdArgs) => ({
        queryKey: [args],
        queryFn: () => getUserById(args),
        contextQueries: {
            projects: {
                queryKey: null,
                queryFn: () => getProjectsByUserId(args)
            }
        }
    }),
    presences: {
        queryKey: null,
        queryFn: () => getUserPresences()
    }
});