import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    Spacer,
    useToast
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { SearchUserResponse, useQueryUserSearchFn } from "../../../api/depr/userSearch";
import { UserSearchForm, UserSearchFormSearch } from "./UserSearchForm";
import { UseUserSearchModalProps } from "./useUserSearchModal";
import { UserSearchResults } from "./UserSearchResultsTable";
import { useBoolean } from "am-tax-fe-core";

export type UserSearchModalProps = Pick<ModalProps, "isOpen" | "onClose">
    & Pick<UseUserSearchModalProps, "onUserSelection" | "onUserAdd">;

export const UserSearchModal: FunctionComponent<UserSearchModalProps> = props => {
    const { onUserSelection, onUserAdd, ...modalProps } = props;
    const [searchResults, setSearchResults] = useState<SearchUserResponse[]>([]);
    const [selectedUser, setSelectedUser] = useState<SearchUserResponse>();
    const [isLoading, {setTrue: startLoading, setFalse: stopLoading}] = useBoolean(false);
    const searchForUsersQuery = useQueryUserSearchFn();
    const toast = useToast();

    const modal = {
        ...modalProps,
        size: "xl",
        closeOnOverlayClick: false
    };

    useEffect(() => {
        if(modalProps.isOpen){
            stopLoading
        }
    }, [modalProps.isOpen]);

    const onSearch = async (search: UserSearchFormSearch) => {
        const searchUserResponses = await searchForUsersQuery(search);
        setSearchResults(searchUserResponses ?? []);
    };

    const onRowClicked = (item: SearchUserResponse) => {
        if(onUserSelection(item)){
            setSelectedUser(item);
        }else{
            toast({
                title: "Invalid selection",
                status: "warning",
                description: "User cannot be added",
            });
            setSelectedUser(undefined);
        }
    }

    const addButtonOnClick = () => {
        if(selectedUser){
            startLoading();
            onUserAdd(selectedUser);
        }
    }

    return (
        <Modal {...modal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Assign User to Project</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {
                        modalProps.isOpen && (
                            <>
                                <UserSearchForm
                                    onSearch={onSearch}
                                />
                                <UserSearchResults
                                    items={searchResults}
                                    onRowClicked={onRowClicked}
                                />
                                <Spacer p={3} />
                                <Box display={"flex"} justifyContent={"right"}>
                                    <Button mr={3} variant="ghost" onClick={modalProps.onClose}>
                                        Cancel
                                    </Button>
                                    <Button colorScheme={"blue"} isDisabled={!selectedUser} isLoading={isLoading} onClick={addButtonOnClick}>
                                        Add User
                                    </Button>
                                </Box>
                            </>
                        )
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};