import { FunctionComponent } from "react";
import { Card, CardBody, CardHeader, HStack, Select, StackDivider, VStack } from "@chakra-ui/react";
import { SetPageTitle } from "../../components/SetPageTitle";
import { Page } from "../Page";
import { Stat, StatLabel, StatNumber, StatHelpText } from "@chakra-ui/react";
export const DashboardPage: FunctionComponent = () => {
    return (
        <Page title="Dashboard" p={".5rem"}>
            <VStack>
                <SetPageTitle title={"Dashboard"} />
                <Select width={"150px"} size="sm" placeholder="Select year...">
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                </Select>
                <HStack padding={5} justify={"center"} spacing={"4"} divider={<StackDivider borderColor="gray.200" />}>
                    <Card p={4} width={"200px"}>
                        <Stat>
                            <StatLabel>Total Number of Assets</StatLabel>
                            <StatNumber>345</StatNumber>
                            <StatHelpText>{}</StatHelpText>
                        </Stat>
                    </Card>
                    <Card p={4} width={"200px"}>
                        <Stat>
                            <StatLabel>Depreciation</StatLabel>
                            <StatNumber>45</StatNumber>
                            <StatHelpText>{}</StatHelpText>
                        </Stat>
                    </Card>
                </HStack>
                <Card>
                    <CardHeader>
                        <b>Depreciation by Time Period Range</b>
                    </CardHeader>
                    <CardBody>{/*TODO: Add time period range line plot graph */}</CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        <b>Gain/Loss Period</b>
                    </CardHeader>
                    <CardBody></CardBody>
                </Card>
            </VStack>
        </Page>
    );
};
