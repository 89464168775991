import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { components, paths } from "../generated/generated-api";
import { fetcher } from "./configure";
import { FileContainer, applyParams, execute, mutationErrorHandler, uploadFile } from "am-tax-fe-core";
import { useToast } from "@chakra-ui/react";
import { queries } from "./queries";
import { ProcessSessionQueryResponse } from "./processSessions";

export function useQueryGetProcessSessionFilesById(args: ProcessSessionFileArgs) {
    return useQuery({
        ...queries.processSessionFiles.byProcessSessionId(args),
        queryFn: () => getProcessSessionFilesById(args),
    });
}

export const getProcessSessionFilesById = async (args: ProcessSessionFileArgs): Promise<ProcessSessionFileQueryResponse[]> => execute(_getFilesById(args));
const processSessionFileByIdPath = "/api/ProcessSessions/{processSessionId}/Files";
export type ProcessSessionFileQueryResponse = components["schemas"]["ProcessSessionFileQueryResponse"];
export type ProcessSessionFileArgs = paths[typeof processSessionFileByIdPath]["get"]["parameters"]["path"];
const _getFilesById = fetcher.path(processSessionFileByIdPath).method("get").create();

export function useMutationProcessSessionCreate() {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (args: UploadProcessSessionFileArgs) => uploadProcessSessionFile(args),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queries.processSessionFiles.files(variables));
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}

export const uploadProcessSessionFile = async (args: UploadProcessSessionFileArgs): Promise<ProcessSessionQueryResponse> =>
    uploadFile(applyParams(uploadProcessSessionFilePath, args), args.files);
const uploadProcessSessionFilePath = "/api/ProcessSessions";
export type UploadProcessSessionFileArgs = { files: FileContainer };
