import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
    getUserActivity,
    getUserActivityActions,
    getUserActivityAreas,
    GetUserActivityArgs,
    getUserActivityProjects,
    getUserActivityUsers
} from "./adminUserActivity";

export const adminUserActivityQueries = createQueryKeys('adminUserActivity', {
    list: (filters: GetUserActivityArgs) => ({
        queryKey: [{filters}],
        queryFn: () => getUserActivity(filters)
    }),
    areas: {
        queryKey: null,
        queryFn: () => getUserActivityAreas()
    },
    users: {
        queryKey: null,
        queryFn: () => getUserActivityUsers(),
    },
    projects: {
        queryKey: null,
        queryFn: () => getUserActivityProjects(),
    },
    actions: {
        queryKey: null,
        queryFn: () => getUserActivityActions(),
    }
});