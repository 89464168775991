import { FunctionComponent, useEffect, useState } from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    Spinner,
    useBoolean
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Shimmer } from "am-tax-fe-core";
import { useMutationCreateProject, useQueryGetProjectById } from "../../../api/depr/adminProjects";

export type CreateProjectModalProps = Pick<ModalProps, "isOpen" | "onClose" | "closeOnOverlayClick">;
export const CreateProjectModal: FunctionComponent<CreateProjectModalProps> = props => {
    const { onClose } = props;

    const [isCreating, { on: startIsCreating, off: stopIsCreating }] = useBoolean(false),
        [projectId, setProjectId] = useState<string>();

    const createProject = useMutationCreateProject();
    const projectQuery = useQueryGetProjectById(projectId ?? "");

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitted },
    } = useForm({
        defaultValues: {
            name: "",
            externalId: "",
            description: "",
            brandColor: "",
            brandLogo: "",
            isActive: true,
        },
    });

    useEffect(() => {
        if (projectId) {
            if (projectQuery.data?.dbStatus !== "Ok") {
                setTimeout(() => {
                    projectQuery.refetch();
                }, 3000);
            } else {
                stopIsCreating();
                setProjectId(undefined);
                reset();
                onClose();
            }
        }
    }, [projectId, projectQuery.dataUpdatedAt]);

    const onCancel = () => {
        reset();
        setProjectId(undefined);
        stopIsCreating();
        onClose();
    };
    return (
        <>
            <Modal size={"xl"} {...props}>
                <ModalOverlay />
                <ModalContent>
                    <form
                        onSubmit={handleSubmit(data => {
                            startIsCreating();
                            createProject
                                .mutateAsync({
                                    name: data.name,
                                    externalId: data.externalId,
                                    description: data.description,
                                    brandColor: data.brandColor,
                                    brandLogo: data.brandLogo,
                                    isActive: data.isActive
                                })
                                .then(response => {
                                    setProjectId(response.id);
                                });
                        })}
                    >
                        <ModalHeader>Create New Project</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {isCreating ? (
                                <Spinner size="xl" />
                            ) : (
                                <Shimmer isLoading={isSubmitted}>
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input placeholder="Project name" {...register("name")} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>External ID</FormLabel>
                                        <Input placeholder="External ID" {...register("externalId")} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Description</FormLabel>
                                        <Input placeholder="Project's description" {...register("description")} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Brand Color</FormLabel>
                                        <Input placeholder="Brand color" {...register("brandColor")} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Brand Logo</FormLabel>
                                        <Input placeholder="Brand logo" {...register("brandLogo")} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Is Active?</FormLabel>
                                        <input type="checkbox" {...register("isActive")} />
                                    </FormControl>
                                </Shimmer>
                            )}
                        </ModalBody>

                        <ModalFooter>
                            <Button isDisabled={isSubmitted} mr={3} variant="ghost" onClick={onCancel}>
                                Cancel
                            </Button>
                            <Button isLoading={isSubmitted} loadingText="Creating..." variant="solid" colorScheme="blue" type="submit">
                                Create
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
};