import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, IconButton, useDisclosure } from "@chakra-ui/react";
import { IconMenu2 } from "@tabler/icons-react";
import { FunctionComponent, useEffect } from "react";
import { DeleteConfirmationDialog, DeleteConfirmationDialogProps } from "../DeleteConfirmationDialog";

export const ProcessesMenuButton: FunctionComponent = () => {
    const deleteDialog = useDisclosure(),
        deleteConfirmationDialog: DeleteConfirmationDialogProps = {
            isOpen: deleteDialog.isOpen,
            onClose: deleteDialog.onClose,
            title: "Delete Client",
            isDisabled: false,
            onDelete: () => {
                //TODO: implement logic for delete
            },
        };

    useEffect(() => {
        deleteDialog.onClose();
    }, []);

    return (
        <Menu>
            <MenuButton as={IconButton} aria-label="Options" icon={<IconMenu2 />} variant="outline" />
            <MenuList>
                <MenuItem>Retain</MenuItem>
                <MenuItem>Download Logs</MenuItem>
                <MenuDivider></MenuDivider>
                <MenuItem onClick={deleteDialog.onOpen}>Delete</MenuItem>
            </MenuList>
            <DeleteConfirmationDialog {...deleteConfirmationDialog}>
                <p>This will permanently delete the associated transactions.</p>
                <br />
                <p>
                    Are you <em>sure</em>?
                </p>
            </DeleteConfirmationDialog>
        </Menu>
    );
};
