import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { IconDownload, IconFileSmile } from "@tabler/icons-react";
import { FunctionComponent } from "react";

export const ProcessesFiles: FunctionComponent = () => {
    return (
        <Box m={1} p={2} rounded={"sm"} border={"solid #64646450 2px"} width={"auto"}>
            <HStack justify={"space-between"}>
                <Flex alignItems={"center"} gap={5}>
                    <IconFileSmile size={"40px"} />
                    <Box>
                        <VStack align={"left"} spacing={0} minWidth={"max-content"}>
                            <Text as={"b"}>File 1 - DevClient - Transactions - 02.03.2023.xlsx</Text>
                            <Box>valid | 1.2k transactions</Box>
                        </VStack>
                    </Box>
                </Flex>

                <Box>
                    <IconDownload></IconDownload> {/*TODO: Need to change to download button*/}
                </Box>
            </HStack>
        </Box>
    );
};
