import { useMutation, useQueryClient } from "@tanstack/react-query";
import { components } from "../generated/generated-api";
import { fetcher } from "./configure";
import { useToast } from "@chakra-ui/react";
import { queries } from "./queries";
import { mutationErrorHandler } from "am-tax-fe-core";
import { execute } from "am-tax-fe-core";

export function useMutationBookCreate() {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (args: CreateBookArgs) => createBookRequest(args),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queries.bookQueries.createBook(variables));
        },
        ...mutationErrorHandler(toast, "Unable to create a new book."),
    });
}
export const createBookRequest = async (args: CreateBookArgs): Promise<AllBooksQueryResponse> => execute(_createBook(args));
const createBookPath = "/api/Books";
export type CreateBookArgs = components["schemas"]["CreateBookRequest"];
export type AllBooksQueryResponse = components["schemas"]["BooksQueryResponse"];
const _createBook = fetcher.path(createBookPath).method("post").create();

//TODO: Fix api below
// export const getBookById = async (args: BookByIdArgs): Promise<BooksQueryResponse> => execute(_getBookById(args));
// const getBookByIdPath = "/api/Book/{bookId}";
// export type BooksQueryResponse = components["schemas"]["BookQueryResponse"];
// export type BookByIdArgs = paths[typeof getBookByIdPath]["get"]["parameters"]["path"];
// const _getBookById = fetcher.path(getBookByIdPath).method("get").create();
