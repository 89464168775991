import { FC, useMemo } from "react";
import { LeftNav as LeftNavCore, NavGroup, NavItemProps, useEnvironmentContext } from "am-tax-fe-core";
import {
    IconActivityHeartbeat,
    IconAssembly,
    IconComponents,
    IconCpu,
    IconHome,
    IconReportAnalytics,
    IconSkateboarding,
    IconUsers
} from "@tabler/icons-react";
import { useQueryMyInformation } from "../api/depr/me";

export const AdminLeftNav: FC = () => {

    const ex = useEnvironmentContext();

    const currentUserQuery = useQueryMyInformation(),
        hasAdmin = !!currentUserQuery.data?.permissions.find(p => p === "HasSystemAdmin"),
        hasDeveloper = !!currentUserQuery.data?.permissions.find(p => p === "HasDeveloper");

    const items = useMemo(() => {
        const items: Array<NavGroup | NavItemProps> = [
            {
                to: "/projects",
                leftIcon: <IconHome size="1rem" />,
                text: "Projects"
            }
        ];

        if(hasAdmin){
            items.push({
                to: `/admin/dashboard`,
                leftIcon: <IconAssembly size="1rem" />,
                text: "Dashboard"
            });
        }

        if(hasDeveloper){
            const group: NavGroup = {
                isGroup: true,
                groupHeading: "Developer",
                items: [
                    {
                        to: `${ex.apiUrl}/hangfire`,
                        leftIcon: <IconCpu />,
                        text: 'Processing',
                        target: "_blank",
                    },
                    {
                        to: `/admin/usage`,
                        leftIcon: <IconSkateboarding />,
                        text: 'Usage'
                    },
                    {
                        to: '/admin/activity-stream',
                        leftIcon: <IconActivityHeartbeat />,
                        text: 'Activity stream'
                    }
                ]
            };
            items.push(group);
        }

        if (hasAdmin) {

            items.push({
                to: `/admin/projects`,
                leftIcon: <IconComponents size="1rem" />,
                text: "Projects"
            });

            items.push({
                to: `/admin/users`,
                leftIcon: <IconUsers size="1rem" />,
                text: "Users"
            });
        }

        if(hasDeveloper || hasAdmin) {
            items.push({
                to: `/admin/reports`,
                leftIcon: <IconReportAnalytics size="1rem" />,
                text: "Reports"
            });
        }

        return items;
    }, [hasAdmin, hasDeveloper]);

    return (
        <LeftNavCore
            logo={{ type: "us" }}
            appName="Administration"
            items={items}
            bgGradient="linear(to-b, gray.900, gray.700)"
        />
    );
};