import { errorMessage, execute, mutationErrorHandler, useToast } from "am-tax-fe-core";
import { components, paths } from "../generated/generated-api";
import { fetcher } from "./configure";
import { queries } from "./queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function useMutationCreateProject() {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (args: ProjectCreationArgs) => createProject(args),
        onSuccess: (data, variables) => {
            queryClient.setQueryData<GetProjectDetailsResponse[]>(queries.adminProjects.all.queryKey, oldData => {
                return [...(oldData ?? []), data];
            });

            queryClient.setQueryData<GetProjectDetailsResponse>(queries.adminProjects.byId(data.id!).queryKey, oldData => data);
        },
        ...mutationErrorHandler(toast, "Unable to create a new project."),
    });
}

export const createProject = async (args: ProjectCreationArgs): Promise<CreateProjectDetailsResponse> => execute(_createProject(args));
const createProjectPath = "/api/admin/projects";
export type ProjectCreationArgs = components["schemas"]["CreateProjectCommand"];
export type CreateProjectDetailsResponse = components["schemas"]["GetProjectDetailsResponse"];
const _createProject = fetcher.path(createProjectPath).method("post").create();

export function useMutationDeleteProjectUser() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteProjectUserArgs) => {
            await deleteProjectUser(args);
        },
        onSuccess: async (response,args) => {
            await queryClient.invalidateQueries(queries.adminProjects.byId(args.projectId)._ctx.users);
            toast({ title: "Project User Removed", description: "User successfully removed from project.", status: "success" });
        },
        ...mutationErrorHandler(toast, "Unable to delete project user."),
    });
}

export const deleteProjectUser = async (args: DeleteProjectUserArgs): Promise<unknown> => execute(_deleteProjectUser(args));
const deleteProjectUserPath = "/api/admin/projects/{projectId}/users/{userId}";
export type DeleteProjectUserArgs = paths[typeof deleteProjectUserPath]["delete"]["parameters"]["path"];
const _deleteProjectUser = fetcher.path(deleteProjectUserPath).method("delete").create();

export function useQueryGetAllProjects() {
    return useQuery({
        ...queries.adminProjects.all,
        queryFn: getProjects,
        ...errorMessage("Unable to get all projects."),
    });
}

export const getProjects = async (): Promise<GetProjectDetailsResponse[]> => execute(_getAllProjects({}));
const getProjectsPath = "/api/admin/projects";
export type GetProjectDetailsResponse = components["schemas"]["GetProjectDetailsResponse"];
const _getAllProjects = fetcher.path(getProjectsPath).method("get").create();

export function useQueryGetProjectById(projectId: string | undefined): any {
    return useQuery({
        ...queries.adminProjects.byId(projectId),
        enabled: !!projectId,
        ...errorMessage("Unable to retrieve project details"),
    });
}

export const getProjectById = async (args: GetProjectByIdArgs): Promise<GetProjectDetailsResponse> => execute(_getProjectById(args));
const getProjectByIdPath = "/api/admin/projects/{projectId}";
export type GetProjectByIdArgs = paths[typeof getProjectByIdPath]["get"]["parameters"]["path"];
const _getProjectById = fetcher.path(getProjectByIdPath).method("get").create();

export function useQueryGetProjectUsers(projectId: string | undefined) {
    return useQuery({
        ...queries.adminProjects.byId(projectId || "" )._ctx.users,
        enabled: !!projectId,
        ...errorMessage("Unable to get project users."),
    });
}
export const getProjectUsers = async (args: ProjectUsersArgs): Promise<ProjectUsersResponse[]> => execute(_getProjectUsers(args));
const projectUsersPath = "/api/admin/projects/{projectId}/users";
export type ProjectUsersArgs = paths[typeof projectUsersPath]["get"]["parameters"]["path"];
export type ProjectUsersResponse = components["schemas"]["AdminUserProjectResponse"];
const _getProjectUsers = fetcher.path(projectUsersPath).method("get").create();

export function useMutationProjectUpdate() {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (args: UpdatedProjectArgs) => updateProject(args),
        onSuccess: async (data, variables) => {
            queryClient.setQueryData<UpdatedProjectResponse[]>(queries.adminProjects.all.queryKey, oldData =>
                oldData
                    ? oldData.map(oldProject =>
                        oldProject.id === data.id!
                            ? data
                            : oldProject,
                    )
                    : oldData);
            queryClient.setQueryData<UpdatedProjectResponse>(queries.adminProjects.byId(data.id!).queryKey, oldData => data);
        },
        ...mutationErrorHandler(toast, "Unable to update project."),
    });
}

export const updateProject = async (args: UpdatedProjectArgs): Promise<UpdatedProjectResponse> => execute(_updateProject(args));
const updateProjectPath = "/api/admin/projects/{projectId}";
export type UpdatedProjectArgs = paths[typeof updateProjectPath]["put"]["parameters"]["path"] & components["schemas"]["UpdateProjectByIdCommand"];
export type UpdatedProjectResponse = components["schemas"]["GetProjectDetailsResponse"];
const _updateProject = fetcher.path(updateProjectPath).method("put").create();