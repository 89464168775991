import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
    getAllProcessSessions,
    getProcessSessionById,
    GetProcessSessionByIdArgs,
    uploadProcessSessionFile,
    UploadProcessSessionFileArgs,
} from "./processSessions";
import { BatchUploadedStats } from "am-tax-fe-core/dist/components/FileUploader/FileUploader";

export const processSessionQueries = createQueryKeys("processSession", {
    all: {
        queryKey: null,
        queryFn: () => getAllProcessSessions(),
    },
    byId: (args: GetProcessSessionByIdArgs) => ({
        queryKey: [args],
        queryFn: () => getProcessSessionById(args),
    }),
    files: (args: UploadProcessSessionFileArgs) => ({
        queryKey: [args],
        queryFn: () => uploadProcessSessionFile(args),
    }),
    stats: (args: BatchUploadedStats) => ({
        queryKey: [args],
        queryFn: null,
    }),
});
