import { Button, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { IconSearch } from "@tabler/icons-react";
import { useForm } from "react-hook-form";

export interface UserSearchFormSearch {
    email: string;
}

export type UserSearchFormProps = {
    onSearch: (search: UserSearchFormSearch) => Promise<void>;
}
export const UserSearchForm: FunctionComponent<UserSearchFormProps> = props => {
    const { onSearch } = props;
    const {
        register,
        handleSubmit,
        formState: { isSubmitting }
    } = useForm({
        values: {
            email: ""
        }
    });

    return (
        <form onSubmit={handleSubmit(onSearch)}>
            <FormControl>
                <Flex gap={2}>
                    <FormLabel>Email:</FormLabel>
                    <InputGroup>
                        <Input placeholder="Search by Email Address..." variant={"outline"} {...register("email")} />
                        <InputLeftElement>
                            <Icon as={IconSearch} color={"blue.200"} />
                        </InputLeftElement>
                    </InputGroup>
                    <Button isLoading={isSubmitting} variant="solid" colorScheme="blue" type="submit">
                        Search
                    </Button>
                </Flex>
            </FormControl>
        </form>
    );
};