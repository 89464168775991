import { FunctionComponent, useState } from "react";
import { Page } from "../../Page";
import {
    FormControl,
    FormLabel,
    HStack,
    Select,
    SelectProps,
    Stack,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import { toDate } from "date-fns";
import { formatTimestamp, formatTimestampStr } from "am-tax-fe-core";
import {
    GetUserActivityResponse,
    useQueryUserActivity,
    useQueryUserActivityActions,
    useQueryUserActivityAreas,
    useQueryUserActivityProjects,
    useQueryUserActivityUsers
} from "../../../api";

export const AdminActivityStreamPage: FunctionComponent = () => {

    const [filters, setFilters] = useState<ActivityTableFilterState>({
        action: undefined,
        user: undefined,
        project: undefined,
        area: undefined
    });

    const tableFilters: IActivityTableFiltersProps = {
        filterState: filters,
        onChange: setFilters
    };

    return (
        <Page title="Activity Stream">
            <Stack>
                <ActivityTableFilters {...tableFilters} />
                <ActivityTable filter={filters} />
            </Stack>
        </Page>
    );
};

interface ActivityTableFilterState {
    action: string | undefined;
    user: string | undefined;
    project: string | undefined;
    area: string | undefined;
}

interface IActivityTableFiltersProps {
    filterState: ActivityTableFilterState;
    onChange: (filterState: ActivityTableFilterState) => void;
}

export const ActivityTableFilters: FunctionComponent<IActivityTableFiltersProps> = ({ filterState, onChange }) => {
    const areasQuery = useQueryUserActivityAreas(),
        usersQuery = useQueryUserActivityUsers(),
        projectsQuery = useQueryUserActivityProjects(),
        actionsQuery = useQueryUserActivityActions();

    const areaOptions = [
            { text: "(none)", value: undefined },
            ...(areasQuery.data ?? []).map(area => ({ text: area, value: area }))
        ].map(opt => <option key={opt.value} value={opt.value ?? undefined}>{opt.text}</option>),
        areaFilter: SelectProps = {
            value: filterState.area,
            children: areaOptions,
            onChange: ev => {
                const selected = ev.target.selectedOptions[0];
                onChange({
                    ...filterState,
                    area: selected.value
                });
            }
        };

    const userOptions = [
            { text: "(none)", value: undefined },
            ...(usersQuery.data ?? []).map(user => ({ text: user.value, value: user.key }))
        ].map(opt => <option key={opt.value} value={opt.value ?? undefined}>{opt.text}</option>),
        userFilter: SelectProps = {
            value: filterState.user,
            children: userOptions,
            onChange: ev => {
                const selected = ev.target.selectedOptions[0];
                onChange({
                    ...filterState,
                    user: selected.value
                })
            }
        };

    const projectOptions = [
            { text: "(none)", value: undefined },
            ...(projectsQuery.data ?? []).map(project => ({ text: project.value, value: project.key }))
        ].map(opt => <option key={opt.value} value={opt.value ?? undefined}>{opt.text}</option>),
        projectFilter: SelectProps = {
            value: filterState.project,
            children: projectOptions,
            onChange: ev => {
                const selected = ev.target.selectedOptions[0];
                onChange({
                    ...filterState,
                    project: selected.value
                })
            }
        };

    const actionOptions = [
            { text: "(none)", value: undefined },
            ...(actionsQuery.data ?? []).map(action => ({ text: action, value: action }))
        ].map(opt => <option key={opt.value} value={opt.value ?? undefined}>{opt.text}</option>),
        actionFilter: SelectProps = {
            value: filterState.action,
            children: actionOptions,
            onChange: ev => {
                const selected = ev.target.selectedOptions[0];
                onChange({
                    ...filterState,
                    action: selected.value
                });
            }
        };


    return (
        <HStack>
            <FormControl>
                <FormLabel>Area</FormLabel>
                <Select {...areaFilter} />
            </FormControl>

            <FormControl isDisabled>
                <FormLabel>User</FormLabel>
                <Select {...userFilter} />
            </FormControl>

            <FormControl isDisabled>
                <FormLabel>Project</FormLabel>
                <Select {...projectFilter} />
            </FormControl>

            <FormControl>
                <FormLabel>Action</FormLabel>
                <Select {...actionFilter} />
            </FormControl>

        </HStack>
    );
};

export const ActivityTable: FunctionComponent<{filter: ActivityTableFilterState}> = ({filter}) => {

    let filters: string[] = [];
    if(filter.area && filter.area != "(none)"){
        filters.push(`Area = ${filter.area}`);
    }
    if(filter.user && filter.user != "(none)"){
        filters.push(`UserId = ${filter.user}`);
    }
    if(filter.project && filter.project != "(none)"){
        filters.push(`ProjectId = ${filter.project}`);
    }
    if(filter.action && filter.action != "(none)"){
        filters.push(`Action = ${filter.action}`);
    }

    const activityQuery = useQueryUserActivity({
        OrderBy: "CreatedOn desc",
        Filter: filters.length > 0 ? filters.join(',') : undefined
    });
    const items = activityQuery.data ?? [];

    return (
        <TableContainer>
            <Table variant="striped">
                <TableCaption>As of: {formatTimestamp(toDate(activityQuery.dataUpdatedAt))}</TableCaption>
                <Thead>
                    <Tr>
                        <Th>User</Th>
                        <Th>Action</Th>
                        <Th>Area</Th>
                        <Th>Detail</Th>
                        <Th>Scope</Th>
                        <Th>Time</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        items.map(item => <ActivityTableRow key={item.id} item={item} />)
                    }
                </Tbody>
            </Table>
        </TableContainer>
    );
};

export const ActivityTableRow: FunctionComponent<{ item: GetUserActivityResponse }> = ({ item }) => {
    return (
        <Tr>
            <Td>
                {item.userDisplayName}
            </Td>
            <Td>
                {item.action}
            </Td>
            <Td>
                {formatMostSignificantAreaPath(item.area)}
            </Td>
            <Td>
                {item.detail}
            </Td>
            <Td>
                {(item.projectName || "System")}
            </Td>
            <Td>
                {formatTimestampStr(item.createdOn)}
            </Td>
        </Tr>
    );
};

const formatMostSignificantAreaPath = (area: string | undefined) => {
    const areaParts = area?.replace("urn:amtax:depr:area:", "").split(":") ?? [],
        msp = areaParts.splice(-1);

    return (
        <span>
            {areaParts.join(":")}{areaParts.length > 0 ? ":" : ""}<strong>{msp}</strong>
        </span>
    );
};