import { FunctionComponent } from "react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Heading,
    Divider,
    DrawerProps,
} from "@chakra-ui/react";

export type ProcessesLeasesDrawerProps = Pick<DrawerProps, "isOpen" | "onClose">;

export const ProcessesLeases: FunctionComponent<ProcessesLeasesDrawerProps> = props => {
    const { isOpen, onClose } = props;
    return (
        <>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <Heading as={"h3"}>Leases</Heading>
                        <Divider />
                    </DrawerHeader>

                    <DrawerBody>{/*TODO: Create lease cards */}</DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};
