import { Box, Card, GridItem, Link, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { ProcessesLeases } from "./ProcessesLeases";

export const ProcessesStatistics: FunctionComponent = () => {
    const leasesDrawer = useDisclosure();
    return (
        <Card variant={"withShadow"} p={5} minWidth={"max-content"}>
            <SimpleGrid columns={2} gap={6} maxWidth={"100%"}>
                <Box>
                    <SimpleGrid columns={2} spacingX={0} spacingY={2}>
                        <GridItem>
                            <b>Transactions</b>
                        </GridItem>
                        <GridItem># of transactions</GridItem>
                        <GridItem>
                            <b>Started On</b>
                        </GridItem>
                        <GridItem>Some start date</GridItem>
                        <GridItem>
                            <b>Completed On</b>
                        </GridItem>
                        <GridItem>Some Completion Date</GridItem>
                    </SimpleGrid>
                </Box>
                <Box>
                    <SimpleGrid columns={2} spacingX={0} spacingY={2}>
                        <GridItem>
                            <b>Status</b>
                        </GridItem>
                        <GridItem>Completed</GridItem>
                        <GridItem>
                            <b>Disposition</b>
                        </GridItem>
                        <GridItem>Success</GridItem>
                        <GridItem>
                            <b>Retained By</b>
                        </GridItem>
                        <GridItem>
                            <Link
                                color="teal.500"
                                onClick={() => {
                                    leasesDrawer.onOpen();
                                }}
                            >
                                2 Leases
                            </Link>
                        </GridItem>
                    </SimpleGrid>
                </Box>
            </SimpleGrid>
            <ProcessesLeases isOpen={leasesDrawer.isOpen} onClose={leasesDrawer.onClose} />
        </Card>
    );
};
