import { createContext, FunctionComponent, useContext, useEffect, useMemo } from "react";
import { ISignalRContext, useSignalRContext } from "./SignalRContextProvider";
import { Outlet, useMatch } from "react-router-dom";
import { HubConnectionState } from "@microsoft/signalr";
import { clearTenantRefId, setTenantRefId } from "../../utils/tenantRefId";

export type IProjectSignalRContext = Pick<ISignalRContext, "connection" | "addListener">

const defaultState: IProjectSignalRContext = {
    connection: undefined,
    addListener: () => {
    }
};


const ProjectSignalRContext = createContext<IProjectSignalRContext>(defaultState);

export const ProjectSignalRContextProvider: FunctionComponent= () => {
    const matchesProject = useMatch("/project/:tenantRefId/*"),
        tenantRefId = matchesProject?.params?.tenantRefId;

    const { connection, addListener, joinProjectGroup, leaveProjectGroup } = useSignalRContext();

    const contextValue: IProjectSignalRContext = useMemo(() => ({
        connection,
        addListener
    }), [connection]);

    useEffect(() => {
        if(tenantRefId){
            setTenantRefId(tenantRefId);
        }

        return () => {
            clearTenantRefId();
        }

    }, [tenantRefId]);

    useEffect(() => {
        if(tenantRefId && connection?.state === HubConnectionState.Connected){
            joinProjectGroup(tenantRefId);
        }

        return () => {
            if(tenantRefId && connection){
                leaveProjectGroup(tenantRefId);
            }
        }
    }, [tenantRefId, connection?.state, joinProjectGroup, leaveProjectGroup])

    return (
        <ProjectSignalRContext.Provider value={contextValue}>
            <Outlet />
        </ProjectSignalRContext.Provider>
    );
};

export const useAdminSignalRContext = () => useContext(ProjectSignalRContext);