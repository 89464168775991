
let currentTenantRefId: string | undefined = undefined;

export function setTenantRefId(tenantRefId: string) {
    currentTenantRefId = tenantRefId;
}

export function clearTenantRefId() {
    currentTenantRefId = undefined;
}

export function getTenantRefId(): string | undefined {
    return currentTenantRefId;
}