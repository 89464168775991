import { FunctionComponent } from "react";
import { Box, Card, Heading, Progress } from "@chakra-ui/react";

export type ProcessesProgressBarProps = {
    fileName: string;
    loadingPercentage: number;
};

export const ProcessesProgressBar: FunctionComponent<ProcessesProgressBarProps> = ({ fileName, loadingPercentage }) => {
    return (
        <Card p={5}>
            <Heading size={"sm"}>Running...</Heading>
            <Box>
                {fileName}
                <Progress colorScheme={"blue"} size={"md"} value={loadingPercentage}></Progress>
                Calculating the depreciation....{loadingPercentage.toString()}%
            </Box>
        </Card>
    );
};
