import { useDisclosure } from "@chakra-ui/react";
import { useMemo } from "react";
import { SearchUserResponse } from "../../../api/depr/userSearch";
import { UserSearchModal } from "./UserSearchModal";

export interface UseUserSearchModalCallbacks {
    open: () => void;
    close: () => void;
}

export interface UseUserSearchModalProps {
    onUserSelection: (user: SearchUserResponse) => boolean;
    onUserAdd: (user: SearchUserResponse) => void;
}

export function useUserSearchModal(initialState: UseUserSearchModalProps): [JSX.Element, UseUserSearchModalCallbacks] {
    const modal = useDisclosure();

    const retVal: UseUserSearchModalCallbacks = useMemo(() => ({
        open: modal.onOpen,
        close: modal.onClose
    }), [modal]);

    const userSearchModal = (
        <UserSearchModal
            isOpen={modal.isOpen}
            onClose={modal.onClose}
            onUserAdd={initialState.onUserAdd}
            onUserSelection={initialState.onUserSelection}
        />
    );

    return [
        userSearchModal,
        retVal
    ];
}