import { useQueryClient } from "@tanstack/react-query";
import { components } from "../generated/generated-api";
import { fetcher } from "./configure";
import { execute } from "am-tax-fe-core";
import { queries } from "./queries";

//Users
export function useQueryUserSearchFn() {
    const queryClient = useQueryClient();

    return async function (args: SearchUsersArgs): Promise<SearchUserResponse[]> {
        if (args.email && args.email.length > 1) {
            return queryClient.fetchQuery({ ...queries.userSearchQueries.search(args), queryFn: () => searchUsers(args) });
        } else {
            return [];
        }
    };
}

export const searchUsers = async (args: SearchUsersArgs): Promise<SearchUserResponse[]> => execute(_searchUsers(args));
const searchUsersPath = "/api/admin/UserSearch";
export type SearchUserResponse = components["schemas"]["UserSearchQueryResponse"];
export type SearchUsersArgs = { email?: string | undefined };
export const _searchUsers = fetcher.path(searchUsersPath).method("post").create();
