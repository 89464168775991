import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queries } from "./queries";
import { execute, mutationErrorHandler } from "am-tax-fe-core";
import { fetcher } from "./configure";
import { paths } from "../generated/generated-api";
import { deleteProjectUser, DeleteProjectUserArgs } from "./adminProjects";

export function useMutationAddUserToProject() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: AddUserToProjectArgs) => addUserToProject(args),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queries.adminProjectUserQueries.addUserToProject(variables));
            toast({ title: "New project user added", description: "User successfully assigned to project.", status: "success" });
        },
        ...mutationErrorHandler(toast, "Unable to assign user to project."),
    });
}

export const addUserToProject = async (args: AddUserToProjectArgs): Promise<unknown> => execute(_addUserToProject(args));
const addUserToProjectPath = "/api/admin/projects/{projectId}/users/{externalUserId}/create";
export type AddUserToProjectArgs = paths[typeof addUserToProjectPath]["post"]["parameters"]["path"];
const _addUserToProject = fetcher.path(addUserToProjectPath).method("post").create();

export function useMutationUpdateProjectUserAccessRole() {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (args: UpdateProjectUserAccessRoleArgs) => updateProjectUserAccessRole(args),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queries.adminProjectUserQueries.userAccessRole(variables));
        },
        ...mutationErrorHandler(toast, "Unable to update project user's access role"),
    });
}

export const updateProjectUserAccessRole = async (args: UpdateProjectUserAccessRoleArgs): Promise<unknown> => execute(_updateProjectUserAccessRole(args));
const updateProjectUserAccessRolePath = "/api/admin/projects/{projectId}/users/{userId}";
export type UpdateProjectUserAccessRoleArgs = paths[typeof updateProjectUserAccessRolePath]["post"]["parameters"]["path"] & {
    role?: "Administrator" | "Contributor" | "Reader";
};
const _updateProjectUserAccessRole = fetcher.path(updateProjectUserAccessRolePath).method("post").create();

export function useMutationDeleteProjectUser() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteProjectUserArgs) => {
            await deleteProjectUser(args);
        },
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queries.adminProjectUserQueries.deleteProject(variables));
            toast({ title: "Project User Removed", description: "User successfully removed from project.", status: "success" });
        },
        ...mutationErrorHandler(toast, "Unable to delete project user."),
    });
}
