import { FunctionComponent } from "react";
import {
    Drawer,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    Spinner
} from "@chakra-ui/react";
import { GetProjectDetailsResponse, useQueryGetProjectById } from "../../../../api/depr/adminProjects";
import { AdminProjectDrawerBody } from "./AdminProjectDrawerBody";

export type AdminProjectDrawerProps = {
    project: GetProjectDetailsResponse | undefined;
} & Pick<DrawerProps, "isOpen" | "onClose" | "placement">;
export const AdminProjectDrawer: FunctionComponent<AdminProjectDrawerProps> = props => {
    const { project, ...drawerProps } = props;

    const projectQuery = useQueryGetProjectById(project?.id);

    return (
        <Drawer size={"md"} {...drawerProps}>
            <DrawerOverlay />
            <DrawerCloseButton />
            <DrawerContent>
                <DrawerHeader>Project Details</DrawerHeader>
                {
                    projectQuery.data ? (
                        <AdminProjectDrawerBody project={projectQuery.data} />
                    ) : (
                        <Spinner />
                    )
                }
            </DrawerContent>
        </Drawer>
    );
};