import { useEffect, useMemo, useState } from "react";
import * as signalr from "@microsoft/signalr";
import { HubConnectionState } from "@microsoft/signalr";

export type DepreciationHubEvents = "ReceiveMessage" | "ReceiveUserAction";
export type DepreciationHubMethods = "JoinAdminGroup" | "LeaveAdminGroup" | "JoinProjectGroup" | "LeaveProjectGroup" | "JoinProjectSessionGroup" | "LeaveProjectSessionGroup";

export interface IUseSignalRProps<T extends string> {
    hubUrl: string;
    listeners: Record<T,(...args: any[]) => void>;
}

export interface IUseSignalRReturn {
    start: () => void;
    stop: () => void;
    isOnline: boolean;
    connection: signalr.HubConnection;
}

export function useSignalR<T extends string>(props: IUseSignalRProps<T>): IUseSignalRReturn {
    const [isOnline, setIsOnline] = useState<boolean>(false);

    const connection = useMemo(() => {
        const hubConnection = new signalr.HubConnectionBuilder()
            .withUrl(props.hubUrl, { withCredentials: false })
            .build();

        hubConnection.onclose(() => {
            setIsOnline(false);
        });

        hubConnection.onreconnected(() => {
            setIsOnline(true);
        });

        hubConnection.onreconnecting(() => {
            setIsOnline(false);
        });

        const methodNames = Object.keys(props.listeners) as Array<T>;
        for(const methodName of methodNames)
        {
            hubConnection.on(methodName as string, props.listeners[methodName] as (...args: any[]) => void);
        }

        return hubConnection;
    }, [props.hubUrl, props.listeners]);

    const start = useMemo(() => {
            return () => {
                if (connection.state === HubConnectionState.Disconnected) {
                     connection.start()
                        .then(() => {
                            console.log("STARTED")
                            setIsOnline(true);
                        })
                        .catch((err: any) => {
                            console.log("START ERROR", err)
                            setIsOnline(false);
                        });
                }
            };
        }, [connection]),
        stop = useMemo(() => {
            return () => {
                for(const methodName of Object.keys(props.listeners))
                {
                    connection.off(methodName);
                }

                connection.stop()
                    .then(() => {
                        console.log("STOPPING");
                    })
            }
        }, [connection]);

    useEffect(() => {
        console.log("isOnlineChurn", isOnline)
    }, [isOnline]);

    const retVal: IUseSignalRReturn = {
        start,
        stop,
        isOnline,
        connection
    };

    return retVal;
}