import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useMemo } from "react";
import { Text } from "@chakra-ui/react";
import { useApiContext, useEnvironmentContext } from "am-tax-fe-core";
import { DepreciationHubEvents, IUseSignalRProps, useSignalR } from "./useSignalR";
import { HubConnection } from "@microsoft/signalr";
import { useQueryMyInformation } from "../../api/depr/me";

export interface ISignalRContext {
    connection: HubConnection | undefined;
    isOnline: boolean;
    addListener: (methodName: DepreciationHubEvents, newMethod: (...args: any[]) => void) => void;
    joinAdminGroup: () => Promise<void>;
    leaveAdminGroup: () => Promise<void>;
    joinProjectGroup: (refId: string) => Promise<void>,
    leaveProjectGroup: (refId: string) => Promise<void>,
    joinProjectSessionGroup: (refId: string, psId: string) => Promise<void>,
    leaveProjectSessionGroup: (refId: string, psId: string) => Promise<void>,
}

const defaultState: ISignalRContext = {
    connection: undefined,
    isOnline: false,
    addListener: () => {
    },
    joinAdminGroup: () => Promise.resolve(),
    leaveAdminGroup: () => Promise.resolve(),
    joinProjectGroup: (refId) => Promise.resolve(),
    leaveProjectGroup: (refId) => Promise.resolve(),
    joinProjectSessionGroup: (refId, psId) => Promise.resolve(),
    leaveProjectSessionGroup: (refId, psId) => Promise.resolve()
};

const SignalRContext = createContext<ISignalRContext>(defaultState);

export const SignalRContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const myInformationQuery = useQueryMyInformation(),
        myInfo = myInformationQuery.data;

    const { accessToken } = useApiContext();

    const { apiUrl } = useEnvironmentContext();
    const signalRProps = useMemo<IUseSignalRProps<DepreciationHubEvents>>(() => ({
        hubUrl: `${apiUrl}/hubs/depr?xaccess_token=${accessToken}`,
        listeners: {
            ReceiveMessage: () => {
            },
            ReceiveUserAction: () => {
            }
        }
    }), [accessToken, apiUrl]);


    const deprHub = useSignalR<DepreciationHubEvents>(signalRProps);


    const contextValue: ISignalRContext = useMemo(() => ({
        connection: deprHub.connection,
        isOnline: deprHub.isOnline,
        addListener: (methodName, newMethod) => {
            deprHub.connection.on(methodName, newMethod);
        },
        joinAdminGroup: () => {
            return deprHub.connection.invoke("joinAdminGroup");
        },
        leaveAdminGroup: () => {
            return deprHub.connection.invoke("leaveAdminGroup");
        },
        joinProjectGroup: (refId) => {
            return deprHub.connection.invoke("joinProjectGroup", refId);
        },
        leaveProjectGroup: (refId) => {
            return deprHub.connection.invoke("leaveProjectGroup", refId);
        },
        joinProjectSessionGroup: (refId, psId) => {
            return deprHub.connection.invoke("joinProjectSessionGroup", refId, psId);
        },
        leaveProjectSessionGroup: (refId, psId) => {
            return deprHub.connection.invoke("leaveProjectSessionGroup", refId, psId);
        }
    }), [deprHub.connection, deprHub.isOnline]);

    useEffect(() => {
        if (accessToken) {
            console.log("START__");
            deprHub.start();
        }
    }, [deprHub, accessToken]);

    return (
        <SignalRContext.Provider value={contextValue}>
            {myInfo ? children : <Text>Loading...</Text>}
        </SignalRContext.Provider>
    );
};

export const useSignalRContext = () => useContext(SignalRContext);