import { FunctionComponent } from "react";
import {
    Avatar,
    Box,
    Button,
    Divider,
    DrawerBody,
    DrawerFooter,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    IconButton,
    Input,
    Select,
    Spacer,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack
} from "@chakra-ui/react";
import {
    GetProjectDetailsResponse,
    ProjectUsersResponse,
    useMutationDeleteProjectUser,
    useMutationProjectUpdate,
    useQueryGetProjectUsers
} from "../../../../api/depr/adminProjects";
import { Shimmer, useConfirm } from "am-tax-fe-core";
import { useForm } from "react-hook-form";
import { IconMoodPuzzled, IconTrash } from "@tabler/icons-react";
import { useMutationAddUserToProject } from "../../../../api/depr/adminProjectUsers";
import { useUserSearchModal } from "../../UserSearchModal";

interface AdminProjectDrawerBodyProps {
    project: GetProjectDetailsResponse;
}

export const AdminProjectDrawerBody: FunctionComponent<AdminProjectDrawerBodyProps> = props => {
    const { project } = props;

    const tabs = [
        {
            title: "Details",
            content: <ProjectDetailsTabPanel key={0} project={project} />
        },
        {
            title: "Users",
            content: <ProjectUsersTabPanel key={1} project={project} />
        }
    ];

    return (
        <DrawerBody>
            <Tabs>
                <TabList>
                    {tabs.map((tab, ix) => (
                        <Tab key={ix}>{tab.title}</Tab>
                    ))}
                </TabList>
                <TabPanels>{tabs.map((tab, ix) => tab.content)}</TabPanels>
            </Tabs>
        </DrawerBody>
    );
};

const ProjectDetailsTabPanel: FunctionComponent<{ project: GetProjectDetailsResponse }> = ({ project }) => {
    const { id, name, externalId, description, brandColor, brandLogo, isActive } = project;

    const updateProject = useMutationProjectUpdate();

    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitted, isDirty }
    } = useForm({
        values: {
            id: id,
            name: name,
            externalId: externalId,
            description: description,
            brandColor: brandColor,
            brandLogo: brandLogo,
            isActive: isActive
        }
    });

    return (
        <TabPanel>
            <TabPanelHeading title="Details" />

            <form
                onSubmit={handleSubmit(data => {
                    updateProject
                        .mutateAsync({
                            projectId: data.id ?? "",
                            name: data.name ?? "",
                            externalId: data.externalId,
                            description: data.description,
                            brandColor: data.brandColor,
                            brandLogo: data.brandLogo,
                            isActive: data.isActive
                        }).then(() => {

                    });
                })}
            >
                <Shimmer isLoading={isSubmitted}>
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input placeholder="Project name" {...register("name")} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>External ID</FormLabel>
                        <Input placeholder="External ID" {...register("externalId")} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Input placeholder="Project's description" {...register("description")} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Brand Color</FormLabel>
                        <Input placeholder="Brand color" {...register("brandColor")} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Brand Logo</FormLabel>
                        <Input placeholder="Brand logo" {...register("brandLogo")} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Is Active?</FormLabel>
                        <input type="checkbox" {...register("isActive")} />
                    </FormControl>
                </Shimmer>
                <DrawerFooter>
                    <Button isDisabled={(isSubmitted || !isDirty)} mr={3} variant="ghost" onClick={() => reset()}>
                        Cancel
                    </Button>
                    <Button isLoading={isSubmitted} isDisabled={!isDirty} loadingText="Updating..." variant="solid"
                            colorScheme="blue"
                            type="submit">
                        Update
                    </Button>
                </DrawerFooter>
            </form>
        </TabPanel>
    );
};

const ProjectUsersTabPanel: FunctionComponent<{ project: GetProjectDetailsResponse }> = ({ project }) => {

    const projectUsersQuery = useQueryGetProjectUsers(project.id);
    const addUserToProject = useMutationAddUserToProject();
    const [UserSearchModal,usm ] = useUserSearchModal({
        onUserSelection: user => user.userSource == "Directory",
        onUserAdd: async user => {
            await addUserToProject.mutateAsync({ projectId: project.id!, externalUserId: user.id });
            usm.close();
            await projectUsersQuery.refetch();
        }
    });

    const assignmentControls = (projectUsersQuery.data ?? []).map(userAssignment => ({
        key: userAssignment.userProjectMappingId!,
        projectId: project.id!,
        item: userAssignment
    })).map(userAssignmentProps => (<ProjectUserControl {...userAssignmentProps} />))

    return (
        <TabPanel>
            <TabPanelHeading title="Users" action={(
                <Button
                    size={"sm"}
                    variant="solid"
                    colorScheme="blue"
                    onClick={usm.open}
                >
                    Add
                </Button>
            )} />
            <VStack>
                {assignmentControls.length > 0 ? assignmentControls : <NoUsersAssigned />}
            </VStack>
            {UserSearchModal}
        </TabPanel>
    );
};

const ProjectUserControl = (props: { item: ProjectUsersResponse, projectId: string }) => {
    const { item, projectId } = props;
    const deleteProjectUser = useMutationDeleteProjectUser();

    const { confirm, ConfirmDialog } = useConfirm({
        title: "Remove User from Project?",
        prompt: "Are you sure you want to remove the user from this project?"
    });

    return (
        <>
            <Flex pt={3} width="100%" key={item.employeeId} alignItems={"center"} gap={3}>
                <Avatar name={item.displayName} />
                <Box>{item.displayName}</Box>
                <Spacer />
                <Box>
                    <Select disabled={true} value={item.role} onChange={() => {
                    }}>
                        <option value="Administrator">Administrator</option>
                        <option value="Contributor">Contributor</option>
                    </Select>
                </Box>
                <Box>
                    <IconButton
                        value={item.userId}
                        aria-label={"Delete item"}
                        icon={<Icon as={IconTrash} />}
                        onClick={async event => {
                            const userId = event.currentTarget.value;
                            const result = await confirm();
                            if (result) {
                                deleteProjectUser.mutateAsync({ projectId: projectId ?? "", userId: userId });
                            }
                        }}
                    />
                </Box>
            </Flex>
            <ConfirmDialog />
        </>
    );
};

const TabPanelHeading = (props: { title: string, action?: JSX.Element }) => (
    <Flex>
        <Heading fontWeight={"700"} fontSize={"1.2rem"} size={"md"}>
            {props.title}
        </Heading>
        {
            props.action && (
                <>
                    <Spacer />
                    {props.action}
                </>
            )
        }
    </Flex>
);

const NoUsersAssigned = () => (
    <VStack mt={5}>
        <Divider />
        <Icon as={IconMoodPuzzled} boxSize={12} />
        <Text>No project assignments found</Text>
        <Divider />
    </VStack>
);