import { Outlet, useLocation, useMatch } from "react-router-dom";
import { ApplicationLayout as CoreApplicationLayout, ErrorBoundary, PageLoading, TrackNavigation } from "am-tax-fe-core";
import { LeftNav } from "./LeftNav";
import { AdminLeftNav } from "./AdminLeftNav";
import { Suspense, useMemo } from "react";
import { SignalRContextProvider } from "../components/SignalRContext";
import { Page } from "./Page";

function ApplicationLayout() {
    const location = useLocation();
    const matchesAdmin = !!useMatch("/admin/*");

    const leftNav = useMemo(() => {
        return matchesAdmin ? <AdminLeftNav /> : <LeftNav />;
    }, [matchesAdmin]);

    return (
        <>
            <ErrorBoundary location={location}>
                <CoreApplicationLayout appName={import.meta.env.VITE_APP_NAME} leftNav={leftNav}>
                    <SignalRContextProvider>
                        <Suspense
                            fallback={
                                <Page title={""}>
                                    <PageLoading text={"Loading Components..."} />
                                </Page>
                            }
                        >
                            <Outlet />
                        </Suspense>
                    </SignalRContextProvider>
                </CoreApplicationLayout>
            </ErrorBoundary>
            <TrackNavigation location={location} />
        </>
    );
}

export default ApplicationLayout;
