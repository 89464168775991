import { FunctionComponent } from "react";
import { SearchUserResponse } from "../../../api/depr/userSearch";
import { DataTable } from "am-tax-fe-core";

export const UserSearchResults: FunctionComponent<{ items: SearchUserResponse[], onRowClicked: (item: SearchUserResponse) => void }> = ({ items, onRowClicked }) => {
    // ----- Data Table Setup -----
    const columns = [
        {
            accessorKey: "firstName",
            enableColumnFilter: false,
            header: "First Name"
        },
        {
            accessorKey: "lastName",
            enableColumnFilter: false,
            header: "Last Name"
        },
        {
            accessorKey: "email",
            enableColumnFilter: false,
            header: "Email"
        }
    ];

    return (
        <DataTable
            columns={columns}
            data={items}
            onRowClicked={onRowClicked}
            noData={"No Users Found"}
        />
    );
};