import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getProcessSessionFilesById, ProcessSessionFileArgs, uploadProcessSessionFile, UploadProcessSessionFileArgs } from "./processSessionFiles";

export const processSessionFilesQueries = createQueryKeys("processSessionFiles", {
    byProcessSessionId: (args: ProcessSessionFileArgs) => ({
        queryKey: [args],
        queryFn: () => getProcessSessionFilesById(args),
    }),
    files: (args: UploadProcessSessionFileArgs) => ({
        queryKey: [args],
        queryFn: () => uploadProcessSessionFile(args),
    }),
});
