import { createContext, FunctionComponent, useContext, useEffect, useMemo } from "react";
import { ISignalRContext, useSignalRContext } from "./SignalRContextProvider";
import { Outlet } from "react-router-dom";
import { DepreciationHubEvents } from "./useSignalR";

export type IAdminSignalRContext = Pick<ISignalRContext, "connection" | "addListener">
export type AdminDepreciationHubEvents = "ReceiveMessage";

const defaultState: IAdminSignalRContext = {
    connection: undefined,
    addListener: (methodName: DepreciationHubEvents, newMethod: (...args:any[]) => void) => {
    }
};


const AdminSignalRContext = createContext<IAdminSignalRContext>(defaultState);

export const AdminSignalRContextProvider: FunctionComponent = () => {

    const { connection, isOnline, addListener, joinAdminGroup, leaveAdminGroup,  } = useSignalRContext();

    const contextValue: IAdminSignalRContext = useMemo(() => ({
        connection,
        addListener
    }), [connection]);

    useEffect(() => {
        console.log('AdminSignalRContextProvider', connection, 'isOnline:', isOnline);
        if(isOnline){
            addListener('ReceiveMessage', (msg) =>{
                console.log("ADMIN|ReceiveMessage", msg);
            });

            joinAdminGroup();
        }

        return () => {
            if(connection){
                leaveAdminGroup();
            }
        }
    }, [connection, isOnline, joinAdminGroup, leaveAdminGroup]);

    return (
        <AdminSignalRContext.Provider value={contextValue}>
            <Outlet />
        </AdminSignalRContext.Provider>
    );
};

export const useAdminSignalRContext = () => useContext(AdminSignalRContext);