import { FunctionComponent } from "react";
import { Page } from "../Page";
import { Box, Card, Divider, Heading, Stack } from "@chakra-ui/react";
import { ProcessesStatistics } from "./ProcessesStatistics";
import { ProcessesFiles } from "./ProcessesFiles";
import { ProcessesMenuButton } from "../../components/ProcessesMenuButton";
import { ProcessesProgressBar } from "./ProcessesProgressBar";

export const ProcessesPage: FunctionComponent = () => {
    return (
        <>
            <Page title={"Process Session"} p={"2rem"}>
                <Stack direction={"column"} spacing={5}>
                    <Box textAlign={"right"}>
                        <ProcessesMenuButton></ProcessesMenuButton>
                    </Box>
                    <ProcessesProgressBar fileName={"Depreciation File.xlsx"} loadingPercentage={80}></ProcessesProgressBar>
                    <Box>
                        <Heading as={"h2"} size="lg">
                            <b>Statistics</b>
                        </Heading>
                        <Divider mb={5} />
                        <ProcessesStatistics></ProcessesStatistics>
                    </Box>
                    <Box>
                        <Heading as={"h2"} size="lg">
                            <b>Files</b>
                        </Heading>
                        <Divider mb={5} />
                        <Card p={5} variant={"withShadow"} minWidth={"max-content"}>
                            <ProcessesFiles></ProcessesFiles>
                        </Card>
                    </Box>
                </Stack>
            </Page>
        </>
    );
};
