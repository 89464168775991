import { FormLabel, Stack, Switch, SwitchProps } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { components } from "../../../../api/generated/generated-api";
import { useMutationAddUserFlag, useMutationRemoveUserFlag, UsersQueryResponse } from "../../../../api/depr/adminUsers";

export type AdminUserFlagsEditorFormProps = {
    user: UsersQueryResponse;
};
export const AdminUserFlagsEditorForm: FunctionComponent<AdminUserFlagsEditorFormProps> = props => {
    const { user } = props;

    const flags: components["schemas"]["UserFlags"][] = [
        "Developer",
        "AddToNewAdmin",
        "AddToNewContributor"
    ];

    const flagControls = flags.map(flag => <AdminUserFlagControl key={flag} flag={flag} user={user} />);

    return (
        <Stack>
            <FormLabel>Flags</FormLabel>
            {flagControls}
        </Stack>
    );
};

type AdminUserFlagControlProps = {
    flag: components["schemas"]["UserFlags"];
    user: UsersQueryResponse;
}

const UserFlagDisplayNames: Record<components["schemas"]["UserFlags"], string> = {
    "None": "None",
    "Developer": "Developer",
    "AddToNewAdmin": "Add to New (admin)",
    "AddToNewContributor": "Add to New (contributor)"
};

export const AdminUserFlagControl: FunctionComponent<AdminUserFlagControlProps> = props => {
    const { flag, user } = props,
        // @ts-ignore
        hasFlag = ((user.flags == 0 ? "" : user.flags)?.split(", ")?.indexOf(flag) ?? -1) >= 0;

    const addUserFlag = useMutationAddUserFlag(),
        removeUserFlag = useMutationRemoveUserFlag();

    const checkbox: SwitchProps = {
        isChecked: hasFlag,
        isDisabled: addUserFlag.isPending || removeUserFlag.isPending,
        onChange: (e) => {
            const isAdd = e.target.checked;
            if (isAdd) {
                addUserFlag.mutate({
                    userId: user.id ?? "",
                    Flag: flag
                });
            } else {
                removeUserFlag.mutate({
                    userId: user.id ?? "",
                    Flag: flag
                });
            }
        }
    };

    return (
        <Switch {...checkbox}>
            {UserFlagDisplayNames[flag]}
        </Switch>
    );
};