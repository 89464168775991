import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { FileContainer, applyParams, errorMessage, execute, mutationErrorHandler, uploadFile } from "am-tax-fe-core";
import { fetcher } from "./configure";
import { queries } from "./queries";
import { components, paths } from "../generated/generated-api";

export function useMutationProcessSessionCreate() {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (args: UploadProcessSessionFileArgs) => uploadProcessSessionFile(args),
        onSuccess: async (data, uploadProcessSessionFile) => {
            queryClient.setQueryData<ProcessSessionQueryResponse>(queries.processSession.files(uploadProcessSessionFile).queryKey, oldData =>
                oldData ? data : oldData,
            );
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}
export const uploadProcessSessionFile = async (args: UploadProcessSessionFileArgs): Promise<ProcessSessionQueryResponse> =>
    uploadFile(applyParams(uploadProcessSessionFilePath, args), args.files);
const uploadProcessSessionFilePath = "/api/ProcessSessions";
export type UploadProcessSessionFileArgs = { files: FileContainer };

export function useQueryGetProcessSessionAll() {
    return useQuery({
        ...queries.processSession.all,
        ...errorMessage("Unable to get all process sessions."),
    });
}

export const getAllProcessSessions = async (): Promise<ProcessSessionQueryResponse[]> => execute(_getAllProcessSessions({}));
const getAllProcessSessionsPath = "/api/ProcessSessions";
const _getAllProcessSessions = fetcher.path(getAllProcessSessionsPath).method("get").create();

export const getProcessSessionById = async (args: GetProcessSessionByIdArgs): Promise<ProcessSessionQueryResponse> => execute(_getProcessSessionById(args));
const processSessionByIdPath = "/api/ProcessSessions/{processSessionId}";
export type ProcessSessionQueryResponse = components["schemas"]["ProcessSessionQueryResponse"];
export type GetProcessSessionByIdArgs = paths[typeof processSessionByIdPath]["get"]["parameters"]["path"];
const _getProcessSessionById = fetcher.path(processSessionByIdPath).method("get").create();
