import { FunctionComponent, useMemo, useRef, useState } from "react";
import { Box, Card, Divider, Heading, VStack } from "@chakra-ui/react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { Page } from "../Page";
import { fakerEN_US } from "@faker-js/faker";
import { AgGridInfiniteScroll, AgGridInfiniteScrollApi } from "am-tax-fe-core";
import { Car } from "../AssetsPage";
import { IDatasource, IGetRowsParams } from "ag-grid-community";

export const AdministrationPage: FunctionComponent = () => {
    const gridApiRef = useRef<AgGridInfiniteScrollApi<Car>>();
    const datasource: IDatasource = useMemo(
        () => ({
            getRows(params: IGetRowsParams) {
                params.successCallback(
                    [
                        { name: "Cameron Allen", city: "Phoenix", dateStarted: fakerEN_US.date.past(), dateCompleted: fakerEN_US.date.recent() },
                        { name: "Sherri Pearce", city: "Chicago", dateStarted: fakerEN_US.date.past(), dateCompleted: fakerEN_US.date.recent() },
                        { name: "Jodi Molina", city: "Montgomery", dateStarted: fakerEN_US.date.past(), dateCompleted: fakerEN_US.date.recent() },
                    ],
                    5,
                );
            },
        }),
        [],
    );

    const [columnDefs]: any = useState([
        { field: "name", flex: 1 },
        { field: "city", flex: 1 },
        { field: "dateStarted", flex: 1 },
        { field: "dateCompleted", flex: 1 },
    ]);

    return (
        <Page title="Administration" p={".5rem"}>
            <VStack padding={5} align={"stretch"} spacing={4}>
                <Box height={"50%"}>
                    <Heading as={"h2"} size={"lg"}>
                        <b>Users</b>
                    </Heading>
                    <Divider mb={5} />
                    <Card variant={"withShadow"}>
                        <AgGridInfiniteScroll gridApiRef={gridApiRef} datasource={datasource} columnDefs={columnDefs}></AgGridInfiniteScroll>
                    </Card>
                </Box>
                <Box>
                    <Heading as={"h2"} size={"lg"}>
                        <b>Retention Settings</b>
                    </Heading>
                    <Divider mb={5} />
                </Box>
            </VStack>
        </Page>
    );
};
