import { LoginPage, LoginPageProps, MsalLoginForm } from "am-tax-fe-core";
import BgImage from "../../assets/images/landingpage.jpg";
import AmTaxLogo from "../../assets/images/amtax.svg";
import { Image } from "@chakra-ui/react";

export const UnauthenticatedLandingPage = () => {

    const AmLogo = (
        <Image src={AmTaxLogo}
               mb={3}
               px={12}
               pb={3}
               width={250}
               borderBottom={"1px solid"}
               borderBottomColor="whiteAlpha.800"
        />
    ),
    loginPage: LoginPageProps = {
        bgImage: `url(${BgImage})`,
        appName: import.meta.env.VITE_APP_NAME,
        logo: AmLogo
    };

    return (
        <LoginPage {...loginPage}>
            <MsalLoginForm />
        </LoginPage>
    );
};