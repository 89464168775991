import { FunctionComponent } from "react";
import { ResponsiveBar } from "@nivo/bar";

export const BarChart: FunctionComponent = () => {
    const data = [
        {
            country: "AD",
            "hot dog": 150,
            "hot dogColor": "hsl(286, 70%, 50%)",
            burger: 69,
            burgerColor: "hsl(85, 70%, 50%)",
            sandwich: 154,
            sandwichColor: "hsl(223, 70%, 50%)",
            kebab: 5,
            kebabColor: "hsl(309, 70%, 50%)",
            fries: 107,
            friesColor: "hsl(254, 70%, 50%)",
            donut: 111,
            donutColor: "hsl(202, 70%, 50%)",
        },
        {
            country: "AE",
            "hot dog": 15,
            "hot dogColor": "hsl(163, 70%, 50%)",
            burger: 61,
            burgerColor: "hsl(193, 70%, 50%)",
            sandwich: 53,
            sandwichColor: "hsl(231, 70%, 50%)",
            kebab: 169,
            kebabColor: "hsl(95, 70%, 50%)",
            fries: 187,
            friesColor: "hsl(275, 70%, 50%)",
            donut: 169,
            donutColor: "hsl(117, 70%, 50%)",
        },
        {
            country: "AF",
            "hot dog": 1,
            "hot dogColor": "hsl(344, 70%, 50%)",
            burger: 73,
            burgerColor: "hsl(252, 70%, 50%)",
            sandwich: 63,
            sandwichColor: "hsl(251, 70%, 50%)",
            kebab: 153,
            kebabColor: "hsl(6, 70%, 50%)",
            fries: 57,
            friesColor: "hsl(168, 70%, 50%)",
            donut: 38,
            donutColor: "hsl(107, 70%, 50%)",
        },
        {
            country: "AG",
            "hot dog": 82,
            "hot dogColor": "hsl(318, 70%, 50%)",
            burger: 104,
            burgerColor: "hsl(276, 70%, 50%)",
            sandwich: 5,
            sandwichColor: "hsl(84, 70%, 50%)",
            kebab: 174,
            kebabColor: "hsl(261, 70%, 50%)",
            fries: 195,
            friesColor: "hsl(186, 70%, 50%)",
            donut: 68,
            donutColor: "hsl(34, 70%, 50%)",
        },
        {
            country: "AI",
            "hot dog": 124,
            "hot dogColor": "hsl(348, 70%, 50%)",
            burger: 148,
            burgerColor: "hsl(64, 70%, 50%)",
            sandwich: 54,
            sandwichColor: "hsl(258, 70%, 50%)",
            kebab: 1,
            kebabColor: "hsl(258, 70%, 50%)",
            fries: 179,
            friesColor: "hsl(155, 70%, 50%)",
            donut: 111,
            donutColor: "hsl(40, 70%, 50%)",
        },
        {
            country: "AL",
            "hot dog": 94,
            "hot dogColor": "hsl(273, 70%, 50%)",
            burger: 90,
            burgerColor: "hsl(129, 70%, 50%)",
            sandwich: 152,
            sandwichColor: "hsl(131, 70%, 50%)",
            kebab: 126,
            kebabColor: "hsl(347, 70%, 50%)",
            fries: 15,
            friesColor: "hsl(108, 70%, 50%)",
            donut: 51,
            donutColor: "hsl(197, 70%, 50%)",
        },
        {
            country: "AM",
            "hot dog": 174,
            "hot dogColor": "hsl(5, 70%, 50%)",
            burger: 193,
            burgerColor: "hsl(283, 70%, 50%)",
            sandwich: 178,
            sandwichColor: "hsl(70, 70%, 50%)",
            kebab: 119,
            kebabColor: "hsl(170, 70%, 50%)",
            fries: 1,
            friesColor: "hsl(308, 70%, 50%)",
            donut: 162,
            donutColor: "hsl(258, 70%, 50%)",
        },
    ];

    return (
        <ResponsiveBar
            data={data}
            keys={["hot dog", "burger", "sandwich", "kebab", "fries", "donut"]}
            indexBy="country"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "nivo" }}
            defs={[
                {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#38bcb2",
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                },
            ]}
            fill={[
                {
                    match: {
                        id: "fries",
                    },
                    id: "dots",
                },
                {
                    match: {
                        id: "sandwich",
                    },
                    id: "lines",
                },
            ]}
            borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "country",
                legendPosition: "middle",
                legendOffset: 32,
                truncateTickAt: 0,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "food",
                legendPosition: "middle",
                legendOffset: -40,
                truncateTickAt: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
            }}
            legends={[
                {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
        />
    );
};
