import { FunctionComponent } from "react";
import {
    Drawer,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    Spinner
} from "@chakra-ui/react";
import { AdminUserDrawerBody } from "./AdminUserDrawerBody";
import { useQueryUserById, UsersQueryResponse } from "../../../../api/depr/adminUsers";

export type AdminUserDrawerProps = {
    user: UsersQueryResponse | undefined;
} & Pick<DrawerProps, "isOpen" | "onClose" | "placement">;
export const AdminUserDrawer: FunctionComponent<AdminUserDrawerProps> = props => {
    const { user, ...drawerProps } = props;

    const userQuery = useQueryUserById(user?.id);

    return (
        <Drawer size={"md"} {...drawerProps}>
            <DrawerOverlay />
            <DrawerCloseButton />
            <DrawerContent>
                <DrawerHeader>User Details</DrawerHeader>
                {
                    userQuery.data ? (
                        <AdminUserDrawerBody user={userQuery.data} />
                    ) : (
                        <Spinner />
                    )
                }
            </DrawerContent>
        </Drawer>
    );
};