import { FunctionComponent, useMemo, useRef } from "react";
import { Box, Select, VStack } from "@chakra-ui/react";
import { SetPageTitle } from "../../components/SetPageTitle";
import { Page } from "../Page";
import { AgGridInfiniteScroll } from "am-tax-fe-core";
import { AgGridInfiniteScrollApi } from "am-tax-fe-core/dist/components/AgGridInifiniteScroll/AgGridInfiniteScroll";
import { IDatasource, IGetRowsParams } from "ag-grid-community";

export interface Car {
    make: string;
    model: string;
    price: number;
}
export const AssetsPage: FunctionComponent = () => {
    /*Grid Logic*/
    const gridApiRef = useRef<AgGridInfiniteScrollApi<Car>>();
    const datasource: IDatasource = useMemo(
        () => ({
            getRows(params: IGetRowsParams) {
                console.log(params);
                params.successCallback(
                    [
                        {
                            make: "Toyota",
                            model: "Celica",
                            price: 35000,
                        },
                        {
                            make: "Ford1",
                            model: "Mondeo",
                            price: 32000,
                        },
                        {
                            make: "Porsche",
                            model: "Boxter",
                            price: 72000,
                        },
                        {
                            make: "Toyota",
                            model: "Celica",
                            price: 35000,
                        },
                        {
                            make: "Ford2",
                            model: "Mondeo",
                            price: 32000,
                        },
                    ],
                    5,
                );
            },
        }),
        [],
    );

    return (
        <Page title="Assets" p={".5rem"}>
            <VStack padding={5} align={"stretch"} spacing={4}>
                <SetPageTitle title={"Assets"} />
                <Box width="fit-content" float={"right"}>
                    <Select width={"150px"} size="sm" placeholder="Select year...">
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                    </Select>
                </Box>

                <AgGridInfiniteScroll
                    gridApiRef={gridApiRef}
                    datasource={datasource}
                    columnDefs={[
                        {
                            field: "make",
                        },
                        {
                            field: "model",
                        },
                        {
                            field: "price",
                        },
                    ]}
                />
            </VStack>
        </Page>
    );
};
