import { FunctionComponent, useMemo, useState } from "react";
import { Page } from "../../Page";
import { Box, Button, ButtonGroup, ButtonProps, Flex, Icon, IconButton, useDisclosure } from "@chakra-ui/react";
import { AgGrid, AgGridProps, Shimmer } from "am-tax-fe-core";
import { ColDef, ColSpanParams, ICellRendererParams } from "ag-grid-community";
import { IconPencil, IconPlus } from "@tabler/icons-react";

import { AdminUserDrawer } from "./AdminUserDrawer";
import { useMutationCreateNewUser, useQueryAllUsers, UsersQueryResponse } from "../../../api/depr/adminUsers";
import { useUserSearchModal } from "../UserSearchModal";

export const AdminUsersPage: FunctionComponent = () => {
    const [selectedUser, setSelectedUser] = useState<UsersQueryResponse>();
    const createUser = useMutationCreateNewUser();

    const userQuery = useQueryAllUsers();

    const editUserDrawer = useDisclosure();
    const [UserSearchModal,usm ] = useUserSearchModal({
        onUserSelection: user => user.userSource == "Directory",
        onUserAdd: async user => {
            await createUser.mutateAsync({id: user.id, source: user.userSource })
            usm.close();
            await userQuery.refetch();
        }
    });

    const addUser: ButtonProps = {
        children: "Add New",
        leftIcon: <Icon as={IconPlus} />,
        onClick: () => {
            editUserDrawer.onClose();
            usm.open();
        },
    };


    const userEditCellRenderer = useMemo(
        () => (props: ICellRendererParams<UsersQueryResponse>) => {
            return (
                <Flex align={"center"}>
                    <ButtonGroup>
                        <IconButton
                            aria-label={"edit item"}
                            icon={<Icon as={IconPencil} />}
                            onClick={() => {
                                setSelectedUser(props.data);
                                editUserDrawer.onOpen();
                            }}
                        ></IconButton>
                    </ButtonGroup>
                </Flex>
            );
        },
        [editUserDrawer, setSelectedUser],
    );

    //---- Grid Column Definition ------
    const columnDefs: Array<ColDef<UsersQueryResponse>> = useMemo(
        () => [
            {
                field: "firstName",
                sortable: true,
                colSpan: (params: ColSpanParams) => {
                    if (params.data) {
                        return 1;
                    } else {
                        return columnDefs.length;
                    }
                },
                cellRenderer: (params: ICellRendererParams) => {
                    if (params.data) {
                        return params.value;
                    } else {
                        return (
                            <Shimmer height="unset" textAlign={"center"} p={0} marginX={"-20px"}>
                                Loading rows...
                            </Shimmer>
                        );
                    }
                },
            },
            {
                field: "lastName",
                sortable: true,
                colSpan: (params: ColSpanParams) => {
                    if (params.data) {
                        return 1;
                    } else {
                        return columnDefs.length;
                    }
                },
            },
            {
                field: "email",
            },
            {
                field: "role",
            },
            {
                headerName: "Edit",
                sortable: false,
                cellRenderer: userEditCellRenderer,
            },
        ],
        [],
    );

    const usersGrid: AgGridProps<UsersQueryResponse> = {
        rowData: userQuery.data,
        columnDefs: columnDefs,
        minGridHeight: '100%',
        fillVerticalSpace: true
    };
    return (
        <>
            <Page title={"User Administration"}>
                <Button mb={"5"} {...addUser}></Button>
                <Box>
                    <AgGrid {...usersGrid}/>
                </Box>
                <AdminUserDrawer {...editUserDrawer} user={selectedUser} />
                {UserSearchModal}
            </Page>
        </>
    );
};