import { FormControl, FormLabel, HStack, Radio, RadioGroup } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { UserRole } from "../../../../enums/UserRole";
import { useMutationSetUserRole, UsersQueryResponse } from "../../../../api/depr/adminUsers";

export type AdminUserRoleEditorFormProps = {
    user: UsersQueryResponse;
};
export const AdminUserRoleEditorForm: FunctionComponent<AdminUserRoleEditorFormProps> = props => {
    const { user } = props;
    const setUserRole = useMutationSetUserRole();

    const onChange = (newValue:string) => {
        setUserRole.mutateAsync({userId: user.id ?? '', Role: newValue as UserRole})
    }

    return (
        <>
            <FormControl as="fieldset" isDisabled={setUserRole.isPending}>
                <FormLabel as="legend">Role</FormLabel>
                <RadioGroup value={(user.role ?? UserRole.None)} onChange={onChange}>
                    <HStack spacing={5} direction="row">
                        <Radio value={UserRole.None}>
                            None
                        </Radio>
                        <Radio value={UserRole.Admin}>
                            Administrator
                        </Radio>
                    </HStack>
                </RadioGroup>
            </FormControl>
        </>
    );
};