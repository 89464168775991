import { FunctionComponent, PropsWithChildren, useRef } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogProps,
    Button,
} from "@chakra-ui/react";

export type DeleteConfirmationDialogProps = Pick<AlertDialogProps, "isOpen" | "onClose"> &
    PropsWithChildren<{
        title: string;
        isDisabled?: boolean;
        onDelete: () => void;
    }>;

export const DeleteConfirmationDialog: FunctionComponent<DeleteConfirmationDialogProps> = props => {
    const { isOpen, onClose, onDelete, isDisabled, children, title } = props;
    const cancelRef = useRef(null);

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>{children}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose} isDisabled={isDisabled}>
                            Cancel
                        </Button>
                        <Button colorScheme="red" onClick={onDelete} ml={3} isDisabled={isDisabled}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
