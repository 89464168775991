import { FunctionComponent } from "react";
import { Page } from "../../Page";
import {
    Avatar,
    AvatarBadge,
    AvatarBadgeProps,
    AvatarProps,
    Icon,
    Spinner,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import { IconWorld } from "@tabler/icons-react";
import { formatDistanceToNow, toDate } from "date-fns";
import { formatTimestamp } from "am-tax-fe-core";
import { useQueryUserPresences, UserPresenceResponse } from "../../../api/depr/adminUsers";

export const AdminUsagePage: FunctionComponent = () => {

    const userPresenceQuery = useQueryUserPresences();

    return (
        <Page title="Usage">
            {
                userPresenceQuery.isLoading ? <Spinner /> : <UserPresenceTable items={(userPresenceQuery.data ?? [])} updatedAt={toDate(userPresenceQuery.dataUpdatedAt)} />
            }
        </Page>
    );
};

const UserPresenceTable: FunctionComponent<{ items: UserPresenceResponse[], updatedAt: Date }> = ({ items, updatedAt }) => {
    return (
        <TableContainer>
            <Table variant="striped">
                <TableCaption>As of: {formatTimestamp(updatedAt)}</TableCaption>
                <Thead>
                    <Tr>
                        <Th />
                        <Th>Name</Th>
                        <Th>Last seen (ago)</Th>
                        <Th isNumeric>Active sessions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        items.map(item => <UserPresenceRow key={item.id} item={item} />)
                    }
                </Tbody>
            </Table>
        </TableContainer>
    );
};

const UserPresenceRow: FunctionComponent<{ item: UserPresenceResponse }> = ({ item }) => {
    return (
        <Tr>
            <Td>
                <UserAvatar {...item} />
            </Td>
            <Td>
                {item.displayName}
            </Td>
            <Td>
                {item.lastSeen && formatDistanceToNow(item.lastSeen)}
            </Td>
            <Td isNumeric>
                {(item.sessions ?? []).length}
            </Td>
        </Tr>
    );
};

type IUserAvatarProps = Pick<UserPresenceResponse, "displayName" | "color" | "initials" | "presence" | "userType">
const UserAvatar: FunctionComponent<IUserAvatarProps> = ({ displayName, color, initials, presence, userType }) => {

    const badge = UserAvatarBadges[presence ?? "Offline"];
    const badgeIcon = userType === "External" ? <Icon as={IconWorld} color="whiteAlpha.400" /> : undefined;

    const avatar: AvatarProps = {
        name: displayName,
        getInitials: () => initials ?? "",
        bg: color
    };

    return (
        <Avatar {...avatar}>
            <AvatarBadge {...badge}>
                {badgeIcon}
            </AvatarBadge>
        </Avatar>
    );
};

const DefaultAvatarBadge: Partial<AvatarBadgeProps> = {
    boxSize: "1.25em"
};
const UserAvatarBadges: Record<"Online" | "Away" | "Offline", AvatarBadgeProps> = {
    "Online": {
        ...DefaultAvatarBadge,
        bg: "green.400",
        borderColor: "green.40"
    },
    "Away": {
        ...DefaultAvatarBadge,
        bg: "tomato",
        borderColor: "papayawhip"
    },
    "Offline": {
        ...DefaultAvatarBadge,
        bg: "gray.400",
        borderColor: "gray.50"
    }
};