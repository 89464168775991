import { fetcher } from "./configure";
import { components, paths } from "../generated/generated-api";
import { errorMessage, execute, mutationErrorHandler, useToast } from "am-tax-fe-core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queries } from "./queries";
import { UserRole } from "../../enums/UserRole";
import { queryKeys } from "../queryKeys";

export function useQueryAllUsers() {
    return useQuery({
        ...queries.adminUsers.all,
        ...errorMessage("Could not get users")
    });
}
export const getAllUsers = async (): Promise<UsersQueryResponse[]> => execute(_getAllUsers({}));
export type UsersQueryResponse = components["schemas"]["UsersQueryResponse"];
const getAllUsersPath = "/api/admin/users";
const _getAllUsers = fetcher.path(getAllUsersPath).method("get").create();

export function useQueryUserById(userId: string | undefined) {
    return useQuery({
        ...queries.adminUsers.byId({ userId: userId ?? "" }),
        enabled: !!userId,
        ...errorMessage("Unable to get user details")
    });
}
export const getUserById = async (args: GetUserByIdArgs): Promise<UsersQueryResponse> => execute(_getUserById(args));
const getUserByIdPath = "/api/admin/users/{userId}";
export type GetUserByIdArgs = paths[typeof getUserByIdPath]["get"]["parameters"]["path"];
const _getUserById = fetcher.path(getUserByIdPath).method("get").create();

export function useMutationCreateNewUser() {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (args: CreateNewUserArgs) => createNewUser(args),
        onSuccess: async (data, variables) => {
            // TODO - replace this with surgical update(s)
            await queryClient.invalidateQueries(queryKeys.users.all);
        },
        ...mutationErrorHandler(toast, "Unable to create a user."),
    });
}
export const createNewUser = async (args: CreateNewUserArgs): Promise<UsersQueryResponse> => execute(_createNewUser(args));
export type CreateNewUserArgs = paths[typeof createNewUserPath]["post"]["requestBody"]["content"]["application/json"];
const createNewUserPath = "/api/admin/users";
const _createNewUser = fetcher.path(createNewUserPath).method("post").create();

export function useMutationSetUserRole() {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (args: SetUserRoleArgs) => setUserRole(args),
        onSuccess: async (data, variables) => {
            // TODO - replace this with surgical updates
            await queryClient.invalidateQueries(queryKeys.users.byId(variables.userId));

            toast({
                title: "User Successfully Added",
                status: "success",
            });
        },
        ...mutationErrorHandler(toast, "Unable to update user role."),
    });
}
export const setUserRole = async (args: SetUserRoleArgs): Promise<unknown> => execute(_setUserRole(args));
const setUserRolePath = "/api/admin/users/{userId}/Role";
export type SetUserRoleArgs = paths[typeof setUserRolePath]["put"]["parameters"]["path"] & { Role: UserRole };
const _setUserRole = fetcher.path(setUserRolePath).method("put").create();

export function useMutationAddUserFlag() {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (args: SetUserFlagsArgs) => addUserFlag(args),
        onSuccess: async (data, variables) => {
            // TODO - replace this with surgical update
            await queryClient.invalidateQueries(queries.adminUsers.byId({userId: variables.userId}));
        },
        ...mutationErrorHandler(toast, "Unable to update user flag."),
    });
}
export const addUserFlag = async (args: SetUserFlagsArgs): Promise<unknown> => execute(_addUserFlag(args));
const setUserFlagsPath = "/api/admin/users/{userId}/Flags/{Flag}"
export type SetUserFlagsArgs = paths[typeof setUserFlagsPath]["post"]["parameters"]["path"];
const _addUserFlag = fetcher.path(setUserFlagsPath).method("post").create();

export function useMutationRemoveUserFlag() {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (args: SetUserFlagsArgs) => removeUserFlag(args),
        onSuccess: async (data, variables) => {
            // TODO - replace this with surgical update
            await queryClient.invalidateQueries(queries.adminUsers.byId({userId: variables.userId}));
        },
        ...mutationErrorHandler(toast, "Unable to update user flag."),
    });
}
export const removeUserFlag = async (args: SetUserFlagsArgs): Promise<unknown> => execute(_removeUserFlag(args));
const _removeUserFlag = fetcher.path(setUserFlagsPath).method("delete").create();


export function useQueryProjectsForUser(userId: string | undefined) {
    return useQuery({
        ...queries.adminUsers.byId({ userId: userId ?? "" })._ctx.projects,
        enabled: !!userId,
        ...errorMessage("Unable to get projects for user")
    });
}
export const getProjectsByUserId = async (args: GetProjectsByUserIdArgs): Promise<ProjectsByUserIdQueryResponse[]> => execute(_getProjectsByUserId(args));
const getProjectsByUserIdPath = "/api/admin/users/{userId}/Projects";
export type GetProjectsByUserIdArgs = paths[typeof getProjectsByUserIdPath]["get"]["parameters"]["path"];
export type ProjectsByUserIdQueryResponse = components["schemas"]["ProjectsByUserIdQueryResponse"];
const _getProjectsByUserId = fetcher.path(getProjectsByUserIdPath).method("get").create();

export function useQueryUserPresences() {
    return useQuery({
        ...queries.adminUsers.presences,
        ...errorMessage("Unable to retrieve presence details")
    });
}
export const getUserPresences = async (): Promise<UserPresenceResponse[]> => execute(_getUserPresences({}));
const getUserPresencesPath = "/api/admin/users/Presences";
export type UserPresenceResponse = components["schemas"]["UserPresenceResponse"];
const _getUserPresences = fetcher.path(getUserPresencesPath).method("get").create();