import { createQueryKeyStore } from "@lukemorales/query-key-factory";

export const queryKeys = createQueryKeyStore({
    book: {
        byId: (id: string) => [id],
        all: null,
    },
    clients: {
        all: null,
    },
    currentUser: {
        me: null,
        projects: null,
    },
    processSession: {
        all: null,
    },
    processSessionFile: {
        all: null,
        byProcessSessionId: (processSessionId: string) => [processSessionId],
    },

    projectMapping: {
        all: null,
    },

    users: {
        all: null,
        byId: (id: string) => [id],
    },
    userPresences: {
        all: null,
    },
});
