import { createQueryKeys } from "@lukemorales/query-key-factory";
import { addUserToProject, AddUserToProjectArgs, updateProjectUserAccessRole, UpdateProjectUserAccessRoleArgs } from "./adminProjectUsers";
import { deleteProjectUser, DeleteProjectUserArgs } from "./adminProjects";

export const adminProjectUserQueries = createQueryKeys("adminProjectUserQueries", {
    deleteProject: (args: DeleteProjectUserArgs) => ({
        queryKey: [args],
        queryFn: () => deleteProjectUser(args),
    }),
    userAccessRole: (args: UpdateProjectUserAccessRoleArgs) => ({
        queryKey: [args],
        queryFn: () => updateProjectUserAccessRole(args),
    }),
    addUserToProject: (args: AddUserToProjectArgs) => ({
        queryKey: [args],
        queryFn: () => addUserToProject(args),
    }),
});
