import { createQueryKeys } from "@lukemorales/query-key-factory";
import { getMyInformation, getMyProjects } from "./me";

export const meQueries = createQueryKeys('me', {
    info: {
        queryKey: null,
        queryFn: () => getMyInformation()
    },
    projects: {
        queryKey: null,
        queryFn: () => getMyProjects()
    }
});