import { FunctionComponent } from "react";
import { ResponsivePie } from "@nivo/pie";
import { dummyDataProps } from "../../pages/TransactionPage";

export const PieChart: FunctionComponent = () => {
    const dummyData: dummyDataProps[] = [
        {
            id: "hack",
            label: "hack",
            value: 356,
            color: "hsl(270, 70%, 50%)",
        },
        {
            id: "javascript",
            label: "javascript",
            value: 130,
            color: "hsl(340, 70%, 50%)",
        },
        {
            id: "c",
            label: "c",
            value: 358,
            color: "hsl(146, 70%, 50%)",
        },
        {
            id: "python",
            label: "python",
            value: 65,
            color: "hsl(237, 70%, 50%)",
        },
        {
            id: "go",
            label: "go",
            value: 505,
            color: "hsl(195, 70%, 50%)",
        },
        {
            id: "sass",
            label: "sass",
            value: 337,
            color: "hsl(64, 70%, 50%)",
        },
        {
            id: "elixir",
            label: "elixir",
            value: 79,
            color: "hsl(338, 70%, 50%)",
        },
        {
            id: "css",
            label: "css",
            value: 180,
            color: "hsl(227, 70%, 50%)",
        },
        {
            id: "php",
            label: "php",
            value: 560,
            color: "hsl(197, 70%, 50%)",
        },
    ];
    return (
        <ResponsivePie
            data={dummyData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
            }}
            defs={[
                {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                },
            ]}
            fill={[
                {
                    match: {
                        id: "ruby",
                    },
                    id: "dots",
                },
                {
                    match: {
                        id: "c",
                    },
                    id: "dots",
                },
                {
                    match: {
                        id: "go",
                    },
                    id: "dots",
                },
                {
                    match: {
                        id: "python",
                    },
                    id: "dots",
                },
                {
                    match: {
                        id: "scala",
                    },
                    id: "lines",
                },
                {
                    match: {
                        id: "lisp",
                    },
                    id: "lines",
                },
                {
                    match: {
                        id: "elixir",
                    },
                    id: "lines",
                },
                {
                    match: {
                        id: "javascript",
                    },
                    id: "lines",
                },
            ]}
            legends={[
                {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemTextColor: "#000",
                            },
                        },
                    ],
                },
            ]}
        />
    );
};
