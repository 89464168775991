import { FunctionComponent, useMemo, useRef, useState } from "react";
import { AgGridInfiniteScroll } from "am-tax-fe-core";
import { Card } from "@chakra-ui/react";
import { AgGridInfiniteScrollApi } from "am-tax-fe-core/dist/components/AgGridInifiniteScroll/AgGridInfiniteScroll";
import { IDatasource, IGetRowsParams } from "ag-grid-community";
import { useQueryGetProcessSessionAll } from "../../api";
import { ProcessSessionQueryResponse } from "../../api";

export interface FileHistoryProps {
    processSessionId: string | undefined;
}

export const FileHistory: FunctionComponent<FileHistoryProps> = ({ processSessionId }) => {
    const gridApiRef = useRef<AgGridInfiniteScrollApi<ProcessSessionQueryResponse>>();
    const allProcessSessionsQuery = useQueryGetProcessSessionAll();

    const datasource: IDatasource = useMemo(
        () => ({
            getRows(params: IGetRowsParams) {
                const top = params.endRow - params.startRow;
                const skip = params.startRow;

                try {
                    const processSessionsData: ProcessSessionQueryResponse[] = allProcessSessionsQuery.data || [];
                    let lastRow = -1;
                    if (processSessionsData.length < top) {
                        lastRow = skip + processSessionsData.length;
                    }
                    params.successCallback(processSessionsData, lastRow);
                } catch (error) {
                    params.failCallback();
                    throw error;
                }
            },
        }),
        [allProcessSessionsQuery],
    );

    const [columnDefs]: any = useState([
        { field: "createdOn", flex: 1 },
        { field: "id", flex: 1 },
    ]);

    return (
        <Card variant={"withShadow"}>
            <AgGridInfiniteScroll gridApiRef={gridApiRef} datasource={datasource} columnDefs={columnDefs}></AgGridInfiniteScroll>
        </Card>
    );
};
