import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { AgGrid, AgGridProps, Shimmer } from "am-tax-fe-core";
import { Page } from "../../Page";
import {
    Box,
    Button,
    ButtonGroup,
    ButtonProps,
    Flex,
    HStack,
    Icon,
    IconButton,
    Spacer,
    useDisclosure
} from "@chakra-ui/react";
import { IconPencil, IconPlus } from "@tabler/icons-react";
import { SetPageTitle } from "../../../components/SetPageTitle";
import { ColDef, ColSpanParams, ICellRendererParams } from "ag-grid-community";
import { CreateProjectModal } from "./CreateProjectModal";
import { useSignalRContext } from "../../../components/SignalRContext";
import { AdminProjectDrawer } from "./AdminProjectDrawer";
import { GetProjectDetailsResponse, useQueryGetAllProjects } from "../../../api/depr/adminProjects";

export const AdminProjectsPage: FunctionComponent = () => {
    const createProjectModal = useDisclosure();
    const editorDrawer = useDisclosure();

    const [selectedProject, setSelectedProject] = useState<GetProjectDetailsResponse>();

    const { connection: signalr } = useSignalRContext();

    const projectsQuery = useQueryGetAllProjects();

    useEffect(() => {
        signalr?.on("ReceiveMessage", msg => {
            console.log("admin_rm", msg);
        });
    }, [signalr]);

    const createButton: ButtonProps = {
        children: "Create",
        leftIcon: <Icon as={IconPlus} />,
        onClick: () => {
            setSelectedProject(undefined);
            editorDrawer.onClose;
            createProjectModal.onOpen();
        },
    };

    //-----Set yp the Edit column cell ----
    const projectsEditCellRenderer = useMemo(
        () => (props: ICellRendererParams<GetProjectDetailsResponse>) => {
            return (
                <Flex align={"center"}>
                    <ButtonGroup>
                        <IconButton
                            aria-label={"edit item"}
                            icon={<Icon as={IconPencil} />}
                            onClick={() => {
                                setSelectedProject(props.data);
                                editorDrawer.onOpen();
                            }}
                        ></IconButton>
                    </ButtonGroup>
                </Flex>
            );
        },
        [editorDrawer, setSelectedProject],
    );

    //---- Grid Column Definition ------
    const columnDefs: Array<ColDef<GetProjectDetailsResponse>> = useMemo(
        () => [
            {
                field: "name",
                sortable: false,
                sort: 'asc',
                colSpan: (params: ColSpanParams) => {
                    if (params.data) {
                        return 1;
                    } else {
                        return columnDefs.length;
                    }
                },
                cellRenderer: (params: ICellRendererParams) => {
                    if (params.data) {
                        return params.value;
                    } else {
                        return (
                            <Shimmer height="unset" textAlign={"center"} p={0} marginX={"-20px"}>
                                Loading rows...
                            </Shimmer>
                        );
                    }
                },
            },
            {
                headerName: "Project Description",
                field: "description",
                sortable: false,
            },
            {
                headerName: "Created On",
                field: "createdOn",
                sortable: false,
                valueFormatter: function (params) {
                    return params.data?.createdOn ? new Date(params.data?.createdOn).toDateString() : "";
                },
            },
            {
                headerName: "Edit",
                sortable: false,
                cellRenderer: projectsEditCellRenderer,
            },
        ],
        [],
    );

    const tenantsGrid: AgGridProps<GetProjectDetailsResponse> = {
        rowData: projectsQuery.data,
        columnDefs: columnDefs,
        isLoading: projectsQuery.isLoading,
        minGridHeight: '100%',
        fillVerticalSpace: true
    };

    return (
        <>
            <Page title={"Administration"}>
                <SetPageTitle title={"Project Administration"}></SetPageTitle>
                <HStack p={1}>
                    <Button {...createButton} />
                    <Spacer />
                </HStack>
                <Box pt={5}>
                    <AgGrid {...tenantsGrid} />
                </Box>
                <CreateProjectModal {...createProjectModal} closeOnOverlayClick={false} />
                <AdminProjectDrawer {...editorDrawer} project={selectedProject} />
            </Page>
        </>
    );
};