import { mergeQueryKeys } from "@lukemorales/query-key-factory";
import { adminUsersQueries } from "./adminUsersQueries";
import { adminUserActivityQueries } from "./adminUserActivityQueries";
import { bookQueries } from "./bookQueries";
import { meQueries } from "./meQueries";
import { adminProjectQueries } from "./adminProjectQueries";
import { processSessionFilesQueries } from "./processSessionFilesQueries";
import { processSessionQueries } from "./processSessionQueries";
import { adminProjectUserQueries } from "./adminProjectUserQueries";
import { userSearchQueries } from "./userSearchQueries";

export const queries = mergeQueryKeys(
    adminProjectQueries,
    adminProjectUserQueries,
    adminUsersQueries,
    adminUserActivityQueries,
    bookQueries,
    meQueries,
    processSessionQueries,
    processSessionFilesQueries,
    userSearchQueries,
);
